/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useRef, useState } from 'react'
import { Popover, Whisper } from "rsuite";
import Footer from '../../Visiteur/FootentContent/Footer';
import { useReactToPrint } from "react-to-print";
import { FadeLoader } from 'react-spinners';
import axios from 'axios'
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';

function VisiteursList() {

    const [visiteurs, setvisiteurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, settotalItems] = useState(0);
    const [pageInput, setPageInput] = useState("");
    const [loadings, Setloadings] = useState(true)


    const [IsPrinting, setIsPrinting] = useState(false);

    const [cahsPrintAll, SetPrintAll] = useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            setIsPrinting(true);
        },
        onAfterPrint: () => {
            setIsPrinting(false);
        },
        onPrintError: (error) => {
            console.error('Erreur d\'impression :', error);
        },
    });


    useEffect(() => {
        window.onafterprint = () => {
            setIsPrinting(false);
        };
        return () => {
            window.onafterprint = null;
        };
    }, []);

    const fetchvisiteurs = async () => {
        axios.get("https://hathaproject.burundientempsreel.com/api/visitors", {
            params: { page: currentPage, size: pageSize }
        })
            .then(response => {
                setvisiteurs(response.data.visiteurs);
                setTotalPages(response.data.totalPages);
                settotalItems(response.data.totalItems);
                Setloadings(false)
            })
            .catch(error => {
                console.error("Error fetching visiteurs:", error);
                Setloadings(false)
            });
    };
    useEffect(() => {
        fetchvisiteurs();
    }, [currentPage, pageSize]);

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handlePageInputChange = (e) => {
        setPageInput(e.target.value);
    };

    const handleGoToPage = () => {
        const pageNumber = parseInt(pageInput);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const generatePageNumbers = () => {
        const maxPagesToShow = 5;
        const pages = [];
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > maxPagesToShow) {
            const offset = Math.floor(maxPagesToShow / 2);
            startPage = currentPage - offset;
            endPage = currentPage + offset;

            if (startPage < 1) {
                startPage = 1;
                endPage = maxPagesToShow;
            } else if (endPage > totalPages) {
                endPage = totalPages;
                startPage = totalPages - maxPagesToShow + 1;
            }
        }

        // Ajout des points de suspension pour les premières pages
        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    className={`py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg`}
                >
                    {1}
                </button>
            );
            if (startPage > 2) {
                pages.push(<span key="dots1">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={`py-2 px-3 mx-1    font-semibold rounded-lg ${currentPage === i ? "bg-gray-700 text-white" : "hover:bg-gray-300 bg-gray-200 text-gray-800"}`}
                >
                    {i}
                </button>
            );
        }

        // Ajout des points de suspension pour les dernières pages
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<span key="dots2">...</span>);
            }
            pages.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    className={`py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };





    const [mobile3, SetMobile3] = useState(window.innerWidth < 342)
    useEffect(() => {
        const HundleSize = () => {
            SetMobile3(window.innerWidth < 342)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])







    const [locations, setLocations] = useState({});

    useEffect(() => {
        const fetchLocations = async () => {
            const locationsObj = {};
            for (const visitor of visiteurs) {
                for (const v of visitor.visitorList) {
                    if (!locationsObj[v.ip]) {
                        const location = await getPosition(v.ip);
                        locationsObj[v.ip] = location || 'Non disponible';
                        setLocations(locationsObj);
                    }
                }
            }
        };

        fetchLocations();
    }, [visiteurs]);

    // Fonction pour obtenir la géolocalisation à partir de l'adresse IP
    const getGeolocationFromIP = async (ip) => {
        try {
            const response = await axios.get(`https://ipinfo.io/${ip}/json`);
            if (response.data) {
                const [latitude, longitude] = response.data.loc.split(',');
                return { latitude, longitude };
            } else {
                console.error('Impossible de trouver les informations de géolocalisation.');
                return null;
            }
        } catch (error) {
            console.error('Erreur lors de la requête de géolocalisation IP :', error.message);
            return null;
        }
    };

    // Fonction pour le géocodage inversé
    const reverseGeocode = async (latitude, longitude) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            if (response.data && response.data.display_name) {
                const location = response.data.display_name;
                return location;
            } else {
                console.error('Impossible de trouver le lieu.');
                return null;
            }
        } catch (error) {
            console.error('Erreur lors de la requête de géocodage inversé :', error.message);
            return null;
        }
    };

    // Fonction principale pour obtenir la localisation à partir de l'adresse IP
    const getLocationFromIP = async (ip) => {
        const geoData = await getGeolocationFromIP(ip);
        if (geoData) {
            const location = await reverseGeocode(geoData.latitude, geoData.longitude);
            return location;
        }
        return null;
    };

    // Fonction asynchrone pour obtenir la position
    const getPosition = async (ip) => {
        try {
            const location = await getLocationFromIP(ip);
            return location;
        } catch (error) {
            console.error('Erreur lors de la récupération de la position :', error.message);
            return null;
        }
    };

    const [mobille, SetMobille] = useState(window.innerWidth < 1287)
    useEffect(() => {
        const hundleSize = () => {
            SetMobille(window.innerWidth < 1287)
        }
        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)
    }, [])


    return (
        <div className='w-full'>
            {loadings && <SpinnerDemarage />}
            <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[92.5vh]' : 'h-[87vh]'}`}>
                <div className=' mx-5 sm:text-2xl    text-[15px]'>Liste des visiteurs le nombre total est: {totalItems}</div>
                <div className='flex items-center mb-2  flex-wrap sm:mt-2 mt-1 w-full justify-between'>
                    <div className='flex px-4  w-full max-sm:mt-2 '>
                        <div className={`w-full justify-between flex flex-wrap items-center`}>
                            <div className='flex items-center'>
                                <div>
                                    <select
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        class="bg-transparent border border-[#AF6E04] px-1 sm:px-2 cursor-pointer h-10 sm:text-lg first-letter:uppercase rounded-lg   block"
                                    >
                                        <option className='text-black' value={1}>1 jour par page</option>
                                        <option className='text-black' value={5}>5 jours par page</option>
                                        <option className='text-black' value={10}>10 jours par page</option>
                                        <option className='text-black' value={20}>20 jours par page</option>
                                        <option className='text-black' value={50}>50 jours par page</option>
                                        <option className='text-black' value={100}>100 jours par page</option>
                                    </select>
                                </div>

                                <div class="flex items-center mx-1  sm:mx-2 ">
                                    <input
                                        type="number"
                                        min='1'
                                        max={totalPages}
                                        value={pageInput}
                                        onChange={handlePageInputChange}
                                        class="bg-transparent text-center  outline-none border border-[#AF6E04] h-10  sm:text-lg first-letter:uppercase rounded-lg  block w-20 mr-2"
                                        placeholder={`1 - ${totalPages}`}
                                    />
                                    <button
                                        onClick={handleGoToPage}
                                        class="py-2 px-4 bg-[#00800023] hover:bg-[#00800049] text-[#AF6E04] font-semibold rounded-lg"
                                    >
                                        Aller
                                    </button>
                                </div>
                            </div>

                            <div className='flex items-center'>
                                {IsPrinting ? (
                                    <>
                                        <div className="w-max flex justify-center relative py-2 px-4">
                                            <div onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className='text-white  block p-2 hover:bg-[#AF6E04] bg-[#AF6E04] transition-all m-0.5 cursor-pointer rounded-lg'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                                </svg>
                                            </div>
                                            <div disabled className='absolute cursor-no-drop  bg-transparent pt-2  pl-8  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <Whisper
                                            trigger='hover'
                                            placement='auto'
                                            speaker={
                                                <Popover>
                                                    Imprimer la liste des visiteurs
                                                </Popover>
                                            }
                                        >
                                            <div onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className='text-white  block p-2 hover:bg-[#AF6E04] bg-[#AF6E04] transition-all m-0.5 cursor-pointer rounded-lg'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                                </svg>
                                            </div>
                                        </Whisper>
                                    </>
                                )}
                            </div>
                        </div>


                    </div>
                </div>
                <div className='w-full pb-3 min-h-[90vh] overflow-x-auto'>
                    <div className={`overflow-x-auto p-3 w-full`}>
                        <table className={`border rounded-sm  ${mobille ? 'w-[100em]' : 'w-full '} border-gray-200`}>
                            <thead>
                                <tr>
                                    <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap' colSpan="2">Liste des visiteurs</th>
                                    <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Date</th>
                                    <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Nombre de visiteurs</th>
                                </tr>
                                <tr>

                                    <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Emplacement</th>
                                    <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Heures de visite</th>
                                    <th className=' p-2 border-r py-2 text-left border-gray-200 text-nowrap'></th>
                                    <th className=' p-2 border-r py-2 text-left border-gray-200 text-nowrap'></th>

                                </tr>
                            </thead>
                            <tbody>
                                {visiteurs.map((visiteur, index) => (
                                    <React.Fragment key={index}>
                                        {visiteur.visitorList.map((visitor, idx) => (
                                            <tr key={idx} className='border-b w-full '>
                                                <td className='p-1 border-r'>
                                                    <div className="font-medium mx-2   p-1">
                                                        {locations[visitor.ip] === undefined ? 'Chargement...' : locations[visitor.ip]}
                                                    </div>
                                                </td>
                                                <td className='p-1 border-r'>
                                                    <div className="block mx-2 p-1">
                                                        {new Date(visitor.createdAt).toLocaleTimeString()}
                                                    </div>
                                                </td>
                                                {idx === 0 && ( // Ne pas répéter la date et le nombre de visiteurs pour chaque visiteur dans la liste
                                                    <React.Fragment>
                                                        <td className='p-1 border-r' rowSpan={visiteur.visitorList.length}>
                                                            {new Date(visiteur.date).toLocaleDateString()}
                                                        </td>
                                                        <td className='p-1 border-r' rowSpan={visiteur.visitorList.length}>
                                                            {visiteur.visitorCount}
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>




                    </div>
                    <div className="flex justify-between px-3 mt-4 flex-wrap">
                        <button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            className="py-2 px-4 bg-[#00800023] hover:bg-[#00800042] text-[#AF6E04] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                        >
                            Précédent
                        </button>
                        <div className="flex flex-wrap ">{generatePageNumbers()}</div>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className="py-2 px-4 bg-[#00800023] hover:bg-[#00800042] text-[#AF6E04] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                        >
                            Suivant
                        </button>
                    </div>
                </div>
                {cahsPrintAll && <PrintAll ref={componentRef} visiteurs={visiteurs} locations={locations} />}
                <Footer />
            </div></div>
    )
}

export default VisiteursList








class PrintAll extends Component {
    render() {
        const { visiteurs, locations } = this.props;
        return (
            <div class="w-full m-1 ">
                <div className='w-full'>
                    <div className=' mx-5 text-2xl  mb-4 font-bold  text-center'>Liste des visiteurs</div>
                    <table className={`border  rounded-sm overflow-hidden w-full border-gray-200`}>
                        <thead>
                            <tr>
                                <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap' colSpan="2">Liste des visiteurs</th>
                                <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Date</th>
                                <th className='border-b-2 p-2 border-r py-2 text-left border-gray-200 text-nowrap'>Nombre de visiteurs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visiteurs.map((visiteur, index) => (
                                <React.Fragment key={index}>
                                    {visiteur.visitorList.map((visitor, idx) => (
                                        <tr key={idx} className='border-b w-full '>
                                            <td className='p-1 border-r'>
                                                <div className="font-medium mx-2   p-1">
                                                    {locations[visitor.ip] === undefined ? 'Chargement...' : locations[visitor.ip]}
                                                </div>
                                            </td>
                                            <td className='p-1 border-r'>
                                                <div className="block mx-2 p-1">
                                                    {new Date(visitor.createdAt).toLocaleTimeString()}
                                                </div>
                                            </td>
                                            {idx === 0 && ( // Ne pas répéter la date et le nombre de visiteurs pour chaque visiteur dans la liste
                                                <React.Fragment>
                                                    <td className='p-1 border-r' rowSpan={visiteur.visitorList.length}>
                                                        {visiteur.date}
                                                    </td>
                                                    <td className='p-1 border-r' rowSpan={visiteur.visitorList.length}>
                                                        {visiteur.visitorCount}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}