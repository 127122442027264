import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
import { useTheme } from '../UseContext/ThemeContext';

function HoraireTravail() {
    const [horaire, sethoraire] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isMargin } = useTheme()

    useEffect(() => {
        // Fonction pour récupérer les horaire depuis le backend
        const fetchhoraire = async () => {
            try {
                const response = await axios.get('https://hathaproject.burundientempsreel.com/horaire');
                sethoraire(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des horaire :', error);
                setLoading(false);
            }
        };


        fetchhoraire();
    }, []);

    function formatHeure(heure) {
        const [hh, mm, ss] = heure.split(':');
        return `${hh}h ${mm}`;
    }


    const [mobile, GetMobile] = useState(window.innerWidth < 528)
    const [mobile2, Setmobile2] = useState(window.innerWidth < 698);

    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 528)
            Setmobile2(window.innerWidth < 698)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    return (
        <div className='w-[95%] px-6 mt-10'>
            {loading && <SpinnerDemarage />}
            <div className={`font-bold mb-4 pl-6 ${isMargin ? mobile2 ? 'text-[17px]' : 'text-xl' : 'text-3xl'} `}>Nous sommes disponibles pour vous servir {`${horaire && horaire.length} jours / 7`}</div>
            <div className='w-full'>
                <table className='w-full border-2 sm:mx-6 border-[#AF6E04] rounded-full'>
                    <thead>
                        <tr>
                            <th class={`border border-gray-300 text-start ${isMargin ? 'text-[12px]' : mobile ? 'text-[12px] text-center' : 'text-[20px]'} py-2 px-4 text-[#AF6E04]`}>Jour</th>
                            <th class={`border border-gray-300 text-start ${isMargin ? 'text-[12px]' : mobile ? 'text-[12px] text-center' : 'text-[20px]'} py-2 px-4 text-[#AF6E04]`}>Heure d'ouverture</th>
                            <th class={`border border-gray-300 text-start ${isMargin ? 'text-[12px]' : mobile ? 'text-[12px] text-center' : 'text-[20px]'} py-2 px-4 text-[#AF6E04]`}>Heure de fermeture</th>
                        </tr>
                    </thead>

                    <tbody className='w-full'>
                        {loading ? (

                            <div className="flex justify-center items-center">
                                <FadeLoader color="#5dca42" loading={loading} size={15} />
                            </div>
                        ) : (
                            horaire.map((data, index) => (
                                <tr>
                                    <td class="border border-gray-300 py-2 px-4">{data.jours}</td>
                                    <td class="border border-gray-300 py-2 px-4">{formatHeure(data.heuredebut)}</td>
                                    <td class="border border-gray-300 py-2 px-4">{formatHeure(data.heurefin)}</td>
                                </tr>
                            ))
                        )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default HoraireTravail
