/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { Popover, Whisper } from 'rsuite';


function RepondreClientEmail({ SetisReondreViaEmail, nom, email }) {
    const [boutLoading, setboutLoading] = useState(false);

    const [title, Gettitle] = useState('')
    const [titleMessage, GettitleMessage] = useState(false)
    const [nom_médicamenAnimate, GetAnimate] = useState('')
    const elemenReftitle = useRef(null)



    const [description, Getdescription] = useState('')
    const [descriptionAnimate, GetdescriptionAnimate] = useState('')
    const [descriptionMessage, GetdescriptionMessage] = useState(false)
    const elemenRefdescription = useRef(null)
    const MAX_CHARACTERS = 1500;



    const HundlesSend = e => {
        // Émission d'un événement socket pour informer du nouveau message
        //socket.current.emit("new-message", { message: "OK" });
        e.preventDefault()
        if (title.trim() == "") {
            GettitleMessage(true)
            GetAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GettitleMessage(false)
                GetAnimate('')
            }, 4000);
            elemenReftitle.current && elemenReftitle.current.focus()
            return false
        }

        else if (description.trim() == "") {
            GetdescriptionMessage(true)
            GetdescriptionAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetdescriptionMessage(false)
                GetdescriptionAnimate('')
            }, 4000);
            elemenRefdescription.current && elemenRefdescription.current.focus()
            return false
        } else if (description.length > MAX_CHARACTERS) {
            toast(
                <div>
                    Vous avez atteint la limite de {MAX_CHARACTERS} caractères. Veuillez raccourcir votre texte.
                </div>
            )
            GetdescriptionAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetdescriptionAnimate('')
            }, 4000);
            elemenRefdescription.current && elemenRefdescription.current.focus()
            return false
        } else {
            setboutLoading(true)
            axios.post("https://hathaproject.burundientempsreel.com/api/Message/sendMessage",
                {
                    email,
                    subject: title,
                    description
                }).then((response) => {
                    setboutLoading(false);
                    toast.success("Message envoyé avec succes!");
                    SetisReondreViaEmail(false)
                }).catch((err) => {
                    setboutLoading(false);
                    if (err.response) {
                        toast.error(err.response.data, {
                            position: 'top-center',
                        });
                    }
                });
        }

    }

    const [mobile1, Setmobile1] = useState(window.innerWidth < 1122);

    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 1122)

        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])





    return (
        <div className=' sm:mt-[5em] mt-4 px-6'>
            <div className={`font-normal  mb-4`}>Repondre le client :<span className='font-bold'>{nom} ( {email} )</span></div>
            <div className='border-[1px] rounded-md p-2 sm:mx-6  border-[#AF6E04] w-[95%]   mx-1'>
                <div>
                    <form onSubmit={HundlesSend} className='w-full px-1'>
                        <div className={`w-full flex  mt-4 ${mobile1 ? 'flex-col gap-3' : ''} items-center`}>
                            <Whisper
                                placement="bottomStart"

                                open={titleMessage}
                                speaker={<Popover>
                                    <div className='text-red-700'>
                                        Le titre est obligatoire !!
                                    </div>
                                </Popover>}
                            >
                                <div className="w-full relative mx-1 mb-2">
                                    <label className="block  mb-2   text-sm font-medium">Titre</label>
                                    <input
                                        value={title}
                                        ref={elemenReftitle}
                                        onChange={(e) => Gettitle(e.target.value)}
                                        className={`w-full  border  outline-none   rounded-md p-2.5  bg-transparent  border-gray-300 ${nom_médicamenAnimate} focus:border-[#AF6E04]`}
                                        placeholder="Nom complet" />
                                </div>
                            </Whisper>
                        </div>
                        <Whisper
                            placement="bottomStart"

                            open={descriptionMessage}
                            speaker={<Popover>
                                <div className='text-red-700'>
                                    L'objectif   est obligatoire !!
                                </div>
                            </Popover>}
                        >
                            <div className="w-full relative mt-4 mb-2">
                                <label htmlFor="company" className="block mb-1 text-sm font-medium ">Objectif</label>
                                <textarea
                                    value={description}
                                    ref={elemenRefdescription}
                                    onCopy={(e) => Getdescription(e.target.value)}
                                    onInput={(e) => Getdescription(e.target.value)}
                                    onChange={(e) => Getdescription(e.target.value)}
                                    className={`w-full  border  outline-none focus:border-[#AF6E04] min-h-[15em]  rounded-md p-2.5  bg-transparent  border-gray-300 ${descriptionAnimate}`}
                                    placeholder="Objectif"
                                >

                                </textarea>
                            </div>
                        </Whisper>

                        <div className=" flex justify-end  w-full">
                            {boutLoading ? (
                                <>
                                    <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-[#AF6E04]    p-2 rounded  text-gray-400">
                                        <input type="submit" id="send" value='Envoyer message' className='pointer-events-none' />
                                        <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                            <FadeLoader
                                                color="rgb(255, 255, 255)"
                                                height={10}
                                                margin={-9}
                                                radius={100}
                                                speedMultiplier={1}
                                                width={1} />
                                        </div>
                                    </button>
                                </>
                            ) : (
                                <div className='w-full flex justify-end gap-2'>
                                    <div onClick={() => SetisReondreViaEmail(false)} className=" mt-3  w-max cursor-pointer flex justify-end     p-2 rounded">
                                        Annuler
                                    </div>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-[#AF6E04]   p-2 rounded  text-white">
                                        <input type="submit" id="send" value="Repondre" className='cursor-pointer'></input>
                                    </label>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RepondreClientEmail