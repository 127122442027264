import React, { useEffect, useState } from 'react'
import { useTheme } from '../UseContext/ThemeContext';
import { Link } from 'react-router-dom';
import axios from 'axios';

import ReactPlayer from 'react-player';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
function Publicite() {
    const { HundlwScrollTop, isMargin, setDatapublicitesHomme } = useTheme();
    const [publicites, setpublicites] = useState([]);
    const [loadings, Setloadings] = useState(true)



    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://hathaproject.burundientempsreel.com/event/getAllevents`);
                setpublicites(response.data.publicites);
                Setloadings(false)
            } catch (error) {
                console.error('Erreur lors de la récupération des events :', error);
                Setloadings(false)
            }
        };

        fetchData();
    }, []);


    const [mobile1, Setmobile1] = useState(window.innerWidth < 1086);
    const [mobile2, Setmobile2] = useState(window.innerWidth < 698);
    const [mobile3, Setmobile3] = useState(window.innerWidth < 447);
    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 1086)
            Setmobile2(window.innerWidth < 698)
            Setmobile3(window.innerWidth < 447)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    return (
        <div className='px-6 mt-5 w-full'>
            {loadings && <SpinnerDemarage />}
            <div className={`font-bold mb-4 font-serif ${isMargin ? mobile2 ? 'text-[17px]' : 'text-xl' : 'text-3xl'} `}>NOS PUBLICITES</div>
            <div className={` ${isMargin ? mobile2 ? 'text-[12px]' : 'text-[15px]' : 'text-[20px]'}`}>
                Nous vous réservons un espace pour vos publicités.
            </div>
            <div className={`${mobile2 ? 'grid grid-cols-2' : ''}`}>
                {publicites.map((data, index) => (
                    index < 4 && <div key={index} data-position={index} className={` flex py-5 ${mobile2 ? 'flex w-[98%] m-0.5  mx-0.5 flex-col' : 'border-b-[0.5em] border-[#b46c0a] border-dotted'}`}>
                        <div className={`relative rounded-lg overflow-hidden border  ${mobile3 ? 'h-[10em]' : mobile2 ? 'w-full h-[15em]' : 'w-[17em] h-[14em]'} `}>
                            {youtubeRegex.test(data.link) ? (
                                <ReactPlayer
                                    controls
                                    url={data.link}
                                    onReady={() => {
                                        document.querySelector(`[data-position="${index}"] .spinners`).style.display = 'none';
                                    }}
                                    className={`w-full h-full inline-flex object-center ${mobile2 ? 'object-contain' : 'object-cover'}`} alt='    '
                                />
                            ) : (
                                <img
                                    src={`https://hathaproject.burundientempsreel.com/uploads/publicites/${data.photo}`}
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinners`).style.display = 'none';
                                    }}
                                    className={`w-full h-full object-center  ${mobile2 ? 'object-contain' : 'object-cover'}`} alt='    '
                                />
                            )}

                            <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center  bg-gray-50 spinners">
                                <svg className="animate-spin h-8 w-8 text-[#AF6E04]" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#AF6E04" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className={`ml-2 w-[80%] ${mobile2 ? 'mt-1' : ''} `}>
                            <div className={`font-bold   hover:no-underline  focus:no-underline  hover:text-[#AF6E04] focus:text-[#AF6E04] text-[#AF6E04]   ${isMargin ? mobile2 ? 'text-[10px]' : 'text-[15px]' : mobile2 ? 'mb-5 text-[13px]' : mobile1 ? 'text-[18px]' : 'text-[30px]'}  `}>{mobile3 ? data.titre && data.titre.length > 20 ? data.titre.slice(0, 15) + "..." : data.titre : data.titre}</div>
                            <div className={`whitespace-break-spaces overflow-hidden ${isMargin ? 'text-[11px]' : mobile2 ? 'text-[11px]' : mobile1 ? 'text-[14px] ' : 'text-[18px]'}`}>
                                {mobile2 ? data.description && data.description.length > 10 ? mobile2 ? data.description.slice(0, 50) : data.description.slice(0, 50) + "...." : data.description : data.description && data.description.length > 594 ? data.description.slice(0, 590) + "...." : data.description}
                            </div>
                            <div className={`  ${mobile1 ? mobile2 ? 'mt-0.5 text-[10px]' : 'text-[12px] mt-4' : 'mt-4'}`}>Publié ,le {data && new Date(data.createdAt).toLocaleDateString()}</div>
                            <Link to="/Publicite" onClick={() => setDatapublicitesHomme(index)} className='flex hover:text-[#b46c0a]  px-3 py-2 rounded-md font-bold text-[15px] sm:text-[20px]  hover:leading-loose transition-all hover:no-underline focus:no-underline mt-2 text-[#b46c0a] w-max items-center'>
                                <span className={`${isMargin ? mobile2 ? 'text-[10px]' : 'text-[13px]' : ''}`} >En savoir plus</span>
                                <span className='ml-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                </span>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Publicite