import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '../UseContext/ThemeContext'
import axios from 'axios';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';

function Articles() {
    const { isMargin, HundlwScrollTop, setDataArticleHomme, } = useTheme()
    const [isLoading, setIsLoading] = useState(true);
    const [article, setArticle] = useState([]);
    const [mobile3, setMobile3] = useState(window.innerWidth < 443);
    const [mobile2, Setmobile2] = useState(window.innerWidth < 698);

    const navig = useNavigate()
    useEffect(() => {
        const hundeSize = () => {
            Setmobile2(window.innerWidth < 698);
            setMobile3(window.innerWidth < 443);
        }
        window.addEventListener('resize', hundeSize)
        return () => {
            window.removeEventListener('resize', hundeSize)
        }

    }, [])


    const fetchArticle = async () => {
        try {
            const response = await axios.get("https://hathaproject.burundientempsreel.com/article/all");
            setArticle(response.data.articles);
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching article:", error);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchArticle();
    }, []);

    return (
        <div className="px-6 mt-2 sm:mt-10 rounded-lg">
            {isLoading && <SpinnerDemarage />}
            <div className={`font-bold mb-4 font-serif ${isMargin ? mobile2 ? 'text-[17px]' : 'text-xl' : 'text-3xl'} `}>
                NOS ACTIVITES (articles)
            </div>
            <div className={` font-semibold ${isMargin ? 'text-[15px]' : 'text-[20px]'}`}>
                Modure de formation:
            </div>
            {article && article.map((data, index) => (
                <div onClick={() => { setDataArticleHomme(index); navig("/document") }} key={index} className='flex cursor-pointer hover:text-gray-500   pl-3 transition-all my-5 text-[18px]'>
                    <div className={`${isMargin ? 'text-[12px]' : mobile3 ? 'text-[15px]' : ''} text-nowrap text-ellipsis overflow-hidden`} key={index}>{data.titre}</div>
                </div>
            ))}

            <div>
                <Link to='/document' onClick={() => HundlwScrollTop()} className='flex hover:text-[#b46c0a] border border-[#b46c0a] px-3 py-2 rounded-md font-bold text-[15px] sm:text-[20px]  hover:leading-loose transition-all hover:no-underline w-max focus:no-underline mt-2 text-[#b46c0a] items-center'>
                    <span className={`${isMargin ? 'text-[13px]' : ''}`}>En savoir plus sur nos réalisations</span>
                    <span className='ml-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                        </svg>
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default Articles
