

/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Popover, Whisper, Button, Dropdown } from 'rsuite';
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate } from 'react-router-dom'

import Footer from '../../Visiteur/FootentContent/Footer';
import { Document, Page } from 'react-pdf';
import { toast } from 'react-toastify';
import axios from 'axios';
import NavBarDocument from './NavBarDocument';
function AjouterDocument() {
    const [boutLoading, setboutLoading] = useState(false);
    const navigate = useNavigate()

    const [Titre, GetTitre] = useState('')
    const [TitreMessage, GetTitreMessage] = useState(false)
    const [nom_médicamenAnimate, GetAnimate] = useState('')
    const elemenRefTitre = useRef(null)


    const [file, setFile] = useState(null);
    const [uploadPdfAnimate, GetuploadPdfAnimate] = useState('')
    const [uploadPdfMessage, GetuploadPdfMessage] = useState(false)
    const elemenRefuploadPdf = useRef(null)



    const onFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    const HundlesSend = e => {
        e.preventDefault()
        if (Titre.trim() == "") {
            GetTitreMessage(true)
            GetAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetTitreMessage(false)
                GetAnimate('')
            }, 4000);
            elemenRefTitre.current && elemenRefTitre.current.focus()
            return false
        }


        else if (file == null) {
            GetuploadPdfMessage(true)
            GetuploadPdfAnimate('animate__animated animate__shakeX border border-red-500')

            setTimeout(() => {
                GetuploadPdfAnimate('')
                GetuploadPdfMessage(false)
            }, 4000);
            elemenRefuploadPdf.current && elemenRefuploadPdf.current.focus()
            return false
        }
        else {
            setboutLoading(true)
            const formData = new FormData();
            formData.append("titre", Titre);
            formData.append("file", file);

            axios.post('https://hathaproject.burundientempsreel.com/article/creer', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toast.success('Enregistrement de document effectué avec succès !');
                    navigate("/documents")
                    setboutLoading(false)
                })
                .catch(error => {
                    if (error.response) {
                        const errorMessage = error.response.data.error;
                        toast.error(errorMessage[0]);
                        setboutLoading(false)
                    } else {
                        toast.error('Une erreur est survenue lors de la création du document.');
                        console.error('Erreur inattendue :', error.message);
                        setboutLoading(false)
                    }
                });


        }

    }

    const [mobile, GetMobile] = useState(window.innerWidth < 1133)

    const [mobile3, SetMobile3] = useState(window.innerWidth < 342)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile3(window.innerWidth < 342)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])



    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 1133)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    return (
        <div className='w-full'>
            <NavBarDocument />
            <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[87vh]' : 'h-[79vh]'}`}>
                <div className="  flex flex-col items-center min-h-[79vh] rounded-lg shadow-lg w-[99%] ">
                    <div className="flex flex-col w-full  items-center justify-center p-2">
                        <div className='w-full flex justify-center'>
                            <div>
                                <div className="uppercase text-[17px] sm:text-[25px] mt-3 font-semibold">Ajouter une nouvelle document</div>
                            </div>
                        </div>
                        <div className="w-full  rounded-lg   h-full ">

                            <div className="p-3  overflow-y-auto overflow-x-hidden">
                                <form onSubmit={HundlesSend} className='w-full'>
                                    <div className={`w-full flex mt-4 ${mobile ? 'flex-col gap-4' : ''} items-center`}>
                                        <Whisper
                                            placement="bottomStart"

                                            open={TitreMessage}
                                            speaker={<Popover>
                                                <div className='text-red-700'>
                                                    Le titre du docment est obligatoire !!
                                                </div>
                                            </Popover>}
                                        >
                                            <div className="w-full relative mx-1 mb-2">
                                                <label className="block  mb-2   text-sm font-medium">Titre du document</label>
                                                <input
                                                    value={Titre}
                                                    ref={elemenRefTitre}
                                                    onChange={(e) => GetTitre(e.target.value)}
                                                    className={`w-full  border  outline-none   rounded-md p-2.5  bg-transparent  border-gray-300 ${nom_médicamenAnimate} focus:border-[#AF6E04]`}
                                                    placeholder="Titre du document"
                                                />
                                            </div>
                                        </Whisper>
                                    </div>

                                    <Whisper
                                        placement="bottomStart"

                                        open={uploadPdfMessage}
                                        speaker={<Popover>
                                            <div className='text-red-700'>
                                                Le documenent est obligatoire !!
                                            </div>
                                        </Popover>}
                                    >
                                        <div className="w-full mt-4 relative mx-1 mb-2">
                                            <label htmlFor='file'
                                                className={`w-full  border  outline-none  block cursor-pointer  rounded-md p-2.5  bg-transparent  border-gray-300 ${uploadPdfAnimate} focus:border-[#AF6E04]`}
                                            >Parcourrir</label>
                                            <input
                                                type='file' accept='.pdf,.png,.jpg,.jepg'
                                                hidden
                                                id='file'
                                                ref={elemenRefuploadPdf}
                                                onChange={onFileChange}
                                                placeholder=" Votre  nom complet ou de la societe"
                                            />
                                        </div>
                                    </Whisper>
                                    {file && (
                                        <div className="mt-4">
                                            {file.type === 'application/pdf' ? (
                                                <Document
                                                    file={file}
                                                    className="border border-gray-300"
                                                >
                                                    <Page pageNumber={1} className="p-4" />
                                                </Document>
                                            ) : (
                                                <img src={URL.createObjectURL(file)} alt='Uploaded' className="max-w-full h-auto border border-gray-300 rounded-md shadow-sm" />
                                            )}
                                        </div>
                                    )}

                                    <div className=" flex justify-end  w-full">
                                        {boutLoading ? (
                                            <>
                                                <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-[#AF6E04]    p-2 rounded  text-gray-400">
                                                    <input type="submit" id="send" value='Enregister' className='pointer-events-none' />
                                                    <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                        <FadeLoader
                                                            color="rgb(255, 255, 255)"
                                                            height={10}
                                                            margin={-9}
                                                            radius={100}
                                                            speedMultiplier={1}
                                                            width={1}
                                                        />
                                                    </div>
                                                </button>
                                            </>
                                        ) : (<>
                                            <div className='flex justify-end w-full'>
                                                <div onClick={() => { setFile(null); GetTitre('') }} for="send" className=" mt-3 cursor-pointer w-max mx-1      p-2 rounded  ">
                                                    Annuler
                                                </div>
                                                <label for="send" className=" mt-3 cursor-pointer w-max   bg-[#AF6E04]   p-2 rounded  text-white">
                                                    <input type="submit" id="send" value="Enregister" className='cursor-pointer'></input>
                                                </label>
                                            </div>
                                        </>)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default AjouterDocument



