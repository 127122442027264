import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../UseContext/ThemeContext';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';


const Pole = () => {
    const { setDataServiceHomme, isMargin } = useTheme()
    const [services, setservices] = useState([]);
    const [loadings, Setloadings] = useState(true)
    const fetchservices = async () => {
        axios.get("https://hathaproject.burundientempsreel.com/service/All")
            .then(response => {
                setservices(response.data.services);
                Setloadings(false)
            })
            .catch(error => {
                console.error("Error fetching services:", error);
                Setloadings(false)
            });
    };
    useEffect(() => {
        fetchservices();
    }, []);


    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 1292)
            GetMobile1(window.innerWidth < 835)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])



    const [mobile, GetMobile] = useState(window.innerWidth < 1292)
    const [mobile1, GetMobile1] = useState(window.innerWidth < 690)

    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 1292)
            GetMobile1(window.innerWidth < 690)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])


    return (
        <div className={`px-6  mt-2 ${isMargin ? 'sm:mt-2' : 'sm:mt-10'}  rounded-lg`}>
            {loadings && <SpinnerDemarage />}
            <div className={`font-bold font-serif ${isMargin ? 'text-xl' : 'text-3xl'} mb-4`}>NOS POLES D’INTERVENTION</div>
            <div className={` grid  ${mobile1 ? 'grid-cols-1' : 'grid-cols-2'}`}>

                {services.map((data, index) => (
                    index < 6 && <div key={index} data-aos="fade-up" className={` ${mobile1 ? 'w-[100%]' : 'w-[95%]'} border mt-5 rounded-xl p-4 border-dashed border-[#b46c0a]  `}>
                        <div className={`mb-2 font-bold   ${isMargin ? 'text-15' : mobile ? 'text-[18px] ' : 'text-[25px] text-wrap  overflow-hidden'}`}>
                            {data.nom}
                        </div>
                        <div className={`${isMargin ? 'text-[13px]' : 'text-[17px]'} w-full`}>
                            {data.description && data.description.length >= 300 ? data.description.slice(0, 300) + "..." : data.description}
                        </div>
                        <div className='w-full flex justify-end my-4 items-center'>
                            <Link to='/service' onClick={() => setDataServiceHomme(index)} className='flex hover:text-[#b46c0a]  rounded-md font-bold text-[15px] sm:text-[20px]  hover:leading-loose transition-all hover:no-underline focus:no-underline mt-2 text-[#b46c0a] items-center'>
                                <span className={`${isMargin ? 'text-[13px]' : ''}`} >En savoir plus</span>
                                <span className='ml-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                </span>
                            </Link>
                        </div>
                    </div>
                ))}

            </div>

        </div>
    );
}

export default Pole;
