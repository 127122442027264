/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Popover, Whisper } from 'rsuite';
function NavBarscontrant() {
    const url = window.location.pathname;
    const { pathname } = useLocation()
    let contrantList = /^\/contrat.*/
    let contrantarchive = /^\/contrat\/expire.*/

    const [mobile3, SetMobile3] = useState(window.innerWidth < 342)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile3(window.innerWidth < 342)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <div className={` border-b border-[#AF6E042f]   ${mobile3 ? 'h-[5.5vh]' : 'h-[8vh]'}  px-2 flex justify-between items-center`}>
            <Whisper
                trigger='hover'
                placement='bottom'
                speaker={
                    <Popover className='text-nowrap'>
                        <div>Cliquer pour ajouter un nouveau contrat</div>
                    </Popover>
                }
            >
                <Link className={`h-max ${mobile3 ? 'text-[13px] px-2 py-1' : 'px-3 py-1 text-[20px]'} w-max rounded-lg  relative text-nowrap  hover:no-underline focus:no-underline bg-[#00800018] flex items-end ${(url == "/contrat/ajouter") && "border-[[#AF6E04]] hover:text-[#AF6E04] focus:text-[#AF6E04] text-[#AF6E04]"}`} to='/contrat/ajouter' >
                    Ajouter
                </Link>
            </Whisper>
            <Whisper
                trigger='hover'
                placement='bottom'
                speaker={
                    <Popover className='text-nowrap'>
                        <div>Cliquer pour afficher la liste des contrats</div>
                    </Popover>
                }
            >
                <Link className={`h-max ${mobile3 ? 'text-[13px] px-2 py-1' : 'px-3 py-1 text-[20px]'} w-max rounded-lg  relative text-nowrap  hover:no-underline focus:no-underline bg-[#00800018] flex items-end ${(contrantList.test(pathname)) && pathname !== '/contrat/ajouter' && !contrantarchive.test(pathname) ? "border-[#AF6E04] hover:text-[#AF6E04] focus:text-[#AF6E04] text-[#AF6E04]" : null}`} to='/contrat'>
                    Afficher
                </Link>
            </Whisper>



            <Whisper
                trigger='hover'
                placement='bottom'
                speaker={
                    <Popover className='text-nowrap'>
                        <div> Cliquez pour afficher : Contrat expiré"</div>
                    </Popover>
                }
            >
                <Link className={`h-max ${mobile3 ? 'text-[13px] px-2 py-1' : 'px-3 py-1 text-[20px]'} w-max rounded-lg  relative text-nowrap  hover:no-underline focus:no-underline bg-[#00800018] flex items-end  ${contrantarchive.test(pathname) && "border-[#AF6E04] hover:text-[#AF6E04] focus:text-[#AF6E04] text-[#AF6E04]"}`} to="/contrat/expire">
                    Expirer
                </Link>
            </Whisper>

        </div >
    )
} export default NavBarscontrant
