/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react'
import Contact from '../HomeVisitor/Contact'
import Remerciement from '../HomeVisitor/Remerciement'
import ScrollReveal from 'scrollreveal'
import axios from 'axios';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
import { useTheme } from '../UseContext/ThemeContext';
import { toast } from 'react-toastify';


/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */


import { FadeLoader } from 'react-spinners';
import { Popover, Whisper } from 'rsuite';
import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';

function DemandArticle() {

    const socket = useRef();
    const uniqueId = uuidv4();


    // Connect to Socket.io
    useEffect(() => {
        socket.current = io("ws://localhost:8006");
        socket.current.emit("new-user-add", {
            role: "visiteur",
            userId: uniqueId,
            // ipAddress: ip,
        });

    }, []);






    const { isMargin } = useTheme()
    const Demande = useRef(null);
    const WhatsApp = useRef(null);
    const Email = useRef(null);
    const Addresse = useRef(null);

    const [isLoading, SetIsLoading] = useState(true)

    useEffect(() => {
        ScrollReveal().reveal(Demande.current, {
            duration: 1000,
            origin: 'top',
            distance: '30%',
            delay: 20,
            easing: 'ease',
            reset: true
        });




        ScrollReveal().reveal(WhatsApp.current, {
            duration: 1000,
            origin: 'top',
            distance: '90%',
            delay: 60,
            easing: 'ease',
            reset: true
        });
        ScrollReveal().reveal(Email.current, {
            duration: 1000,
            origin: 'left',
            distance: '120%',
            delay: 80,
            easing: 'ease',
            reset: true
        });
        ScrollReveal().reveal(Addresse.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '150%',
            delay: 100,
            easing: 'ease',
            reset: true
        });
    }, []);
    const [data, setData] = useState({});
    useEffect(() => {
        axios.get('https://hathaproject.burundientempsreel.com/coord')
            .then(response => {
                setData(response.data);
                SetIsLoading(false)
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des coordonnées: ", error);
                SetIsLoading(false)

            });
    }, []);


    const [mobile1, Setmobile1] = useState(window.innerWidth < 777);
    const [mobile2, Setmobile2] = useState(window.innerWidth < 584);
    const [mobile3, Setmobile3] = useState(window.innerWidth < 425);
    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 777)
            Setmobile2(window.innerWidth < 584)
            Setmobile3(window.innerWidth < 425)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])



    const [boutLoading, setboutLoading] = useState(false);

    const [nomcomplet, Getnomcomplet] = useState('')
    const [nomcompletMessage, GetnomcompletMessage] = useState(false)
    const [nom_médicamenAnimate, GetAnimate] = useState('')
    const elemenRefnomcomplet = useRef(null)


    const [tel, Gettel] = useState('')
    const [telMessage, GettelMessage] = useState(false)
    const [telAnimate, GettelAnimate] = useState('')
    const elemenReftel = useRef(null)

    const [email, Getemail] = useState('')
    const [informationEmail, GetinformationEmail] = useState('')
    const [emailMessage, GetemailMessage] = useState(false)
    const [emailAnimate, GetemailAnimate] = useState('')
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const elemenRefemail = useRef(null)

    const [categorie, Getcategorie] = useState('')
    const [categorieMessage, GetcategorieMessage] = useState(false)
    const [categorieAnimate, GetcategorieAnimate] = useState('')
    const elemenRefcategorie = useRef(null)



    const [description, Getdescription] = useState('')
    const [descriptionAnimate, GetdescriptionAnimate] = useState('')
    const [descriptionMessage, GetdescriptionMessage] = useState(false)
    const elemenRefdescription = useRef(null)
    const MAX_CHARACTERS = 1500;



    const HundlesSend = e => {
        // Émission d'un événement socket pour informer du nouveau message
        //socket.current.emit("new-message", { message: "OK" });
        e.preventDefault()
        if (nomcomplet.trim() == "") {
            GetnomcompletMessage(true)
            GetAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetnomcompletMessage(false)
                GetAnimate('')
            }, 4000);
            elemenRefnomcomplet.current && elemenRefnomcomplet.current.focus()
            return false
        } else if (tel.trim() == "") {
            GettelMessage(true)
            GettelAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GettelAnimate('')
                GettelMessage(false)
            }, 4000);
            elemenReftel.current && elemenReftel.current.focus()
            return false
        }
        else if (email.trim() == "") {
            GetemailMessage(true)
            GetemailAnimate('animate__animated animate__shakeX border border-red-500')
            GetinformationEmail('Votre email est obligatoire !!')
            setTimeout(() => {
                GetemailAnimate('')
                GetemailMessage(false)
                GetinformationEmail('')
            }, 4000);
            elemenRefemail.current && elemenRefemail.current.focus()
            return false
        } else if (!email.trim().match(emailRegex)) {
            GetemailMessage(true)
            GetemailAnimate('animate__animated animate__shakeX border border-red-500')
            GetinformationEmail('Votre email du client est incorrecte !!')
            setTimeout(() => {
                GetemailAnimate('')
                GetemailMessage(false)
                GetinformationEmail('')
            }, 4000);
            elemenRefemail.current && elemenRefemail.current.focus()
            return false


        } else if (categorie.trim() == "") {
            GetcategorieMessage(true)
            GetcategorieAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetcategorieAnimate('')
                GetcategorieMessage(false)
            }, 4000);
            elemenRefcategorie.current && elemenRefcategorie.current.focus()
            return false
        }
        else if (description.trim() == "") {
            GetdescriptionMessage(true)
            GetdescriptionAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetdescriptionMessage(false)
                GetdescriptionAnimate('')
            }, 4000);
            elemenRefdescription.current && elemenRefdescription.current.focus()
            return false
        } else if (description.length > MAX_CHARACTERS) {
            toast(
                <div>
                    Vous avez atteint la limite de {MAX_CHARACTERS} caractères. Veuillez raccourcir votre texte.
                </div>
            )
            GetdescriptionAnimate('animate__animated animate__shakeX border border-red-500')
            setTimeout(() => {
                GetdescriptionAnimate('')
            }, 4000);
            elemenRefdescription.current && elemenRefdescription.current.focus()
            return false
        } else {
            setboutLoading(true)
            axios.post('https://hathaproject.burundientempsreel.com/demande/ajouter', {
                nomcomplet: nomcomplet,
                tel: tel,
                email: email,
                categorie: categorie,
                description: description
            })
                .then(response => {
                    toast.success('Votre demande de service a été envoyée avec succès.');
                    Getnomcomplet("")
                    Gettel("")
                    Getemail("")
                    Getcategorie("")
                    Getdescription("")
                    setboutLoading(false)
                    // Émission d'un événement socket pour informer du nouveau message
                    socket.current.emit("new-message", { message: "Nouveau message envoyé !" });
                })
                .catch(error => {
                    if (error.response) {
                        // Erreurs renvoyées par le backend
                        const errorMessage = error.response.data.error;
                        toast.error(Array.isArray(errorMessage) ? errorMessage.join('\n') : errorMessage);
                        setboutLoading(false)
                    } else {
                        console.error('Erreur lors de l\'envoi de la demande de service:', error.response.data);
                        toast.error('Erreur lors de l\'envoi de la demande de service.');
                        setboutLoading(false)
                    }
                });

        }

    }


    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 1122)
            Setmobile2(window.innerWidth < 698)
            Setmobile3(window.innerWidth < 447)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [article, setArticle] = useState([]);

    const fetchArticle = async () => {
        try {
            const response = await axios.get("https://hathaproject.burundientempsreel.com/article/all");
            setArticle(response.data.articles);
            SetIsLoading(false)
        } catch (error) {
            console.error("Error fetching article:", error);
            SetIsLoading(false)
        }
    };

    useEffect(() => {
        fetchArticle();
    }, []);

    return (
        <div className='relative'>
            {isLoading && <SpinnerDemarage />}
            <div className='relative h-[35vh] overflow-hidden'>
                <img src='../images/contact-us.png' className='w-full object-cover object-bottom' alt='   ' />
                <div className='absolute  flex justify-center  top-0 left-0 items-center w-full h-full'>
                    <div ref={Demande} className={` ${isMargin ? mobile3 ? 'text-[2em]' : 'text-[2.5em]' : mobile2 ? 'text-[30px]' : 'text-[60px]'} stroketext1`}>Demande maintenant</div>
                </div>
            </div>

            <div className={`mt-5 p-4  text-[#AF6E04] ${isMargin ? 'text-[15px]' : mobile1 ? 'text-[20px]' : 'text-[25px]'} `}>
                Prenez contact et exprimez vos besoins
            </div>

            <div className={`w-full flex ${mobile2 ? 'flex-col gap-2' : ''} p-4  justify-around`}>
                <div ref={WhatsApp} href={`whatsapp://send?phone=${data.tel}`} className='flex hover:no-underline focus:no-underline'>
                    <div className={`bg-[#AF6E04]   rounded-full flex justify-center bi text-white items-center ${isMargin ? 'p-1 w-[3em] h-[3em] ' : mobile2 ? ' w-[3em] my-2 h-[3em]' : ' p-3 w-[4em] h-[4em]'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                        </svg>
                    </div>
                    <div className={`ml-1  ${isMargin ? 'text-[12px]' : ''}`}>
                        <div className='text-gray-400 flex hover:no-underline focus:no-underline'>WhatsApp</div>
                        <div>
                            {data.tel && data.tel.split("ou").map((data, index) => (
                                <a href={`whatsapp://send?phone=${data}`}>{data}</a>
                            ))}
                        </div>
                    </div>
                </div>
                <a ref={Email} target='_blank' href={`mailto:${data.email}`} className='flex hover:no-underline focus:no-underline'>
                    <div className={`bg-[#AF6E04] rounded-full flex justify-center items-center ${isMargin ? 'p-1 w-[3em] h-[3em] ' : mobile2 ? ' w-[3em] my-2 h-[3em]' : ' p-3 w-[4em] h-[4em]'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi text-white bi-telephone-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                        </svg>
                    </div>
                    <div className={`ml-1  ${isMargin ? 'text-[12px]' : ''}`}>
                        <div className='text-gray-400 flex hover:no-underline focus:no-underline'>Email</div>
                        <div className={`mt-2  flex hover:no-underline focus:no-underline`}>{data.email}</div>
                    </div>
                </a>
                <div ref={Addresse} className='flex hover:no-underline focus:no-underline'>
                    <div className={`bg-[#AF6E04] p-3  rounded-full flex justify-center items-center ${isMargin ? 'p-1 w-[3em] h-[3em] ' : mobile2 ? ' w-[3em] my-2 h-[3em]' : ' p-3 w-[4em] h-[4em]'} `}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi text-white bi-telephone-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                    </div>
                    <div className={`ml-1  ${isMargin ? 'text-[12px]' : ''}`}>
                        <div className='text-gray-400 flex hover:no-underline focus:no-underline'>Addresse</div>
                        <div className='mt-2  flex hover:no-underline focus:no-underline'>{data.address}</div>
                    </div>
                </div>
            </div>
            <div className=' sm:mt-[5em] mt-4 px-6'>
                <div className={`font-bold mb-4 font-serif ${isMargin ? mobile2 ? 'text-[17px]' : 'text-xl' : 'text-3xl'} `}>VOS BESOINS</div>
                <div className={` ${isMargin ? 'text-[15px]' : 'text-[20px]'} my-1`}>Notre engagement, c’est d’être à votre écoute et à votre article. <br />Contactez-nous: </div>
                <div className='border-[1px] rounded-md p-2 sm:mx-6  border-[#AF6E04] w-[95%]   mx-1'>
                    <div>
                        <form onSubmit={HundlesSend} className='w-full px-1'>
                            <div className={`w-full flex  mt-4 ${mobile1 ? 'flex-col gap-3' : ''} items-center`}>
                                <Whisper
                                    placement="bottomStart"

                                    open={nomcompletMessage}
                                    speaker={<Popover>
                                        <div className='text-red-700'>
                                            Votre  nom complet est obligatoire !!
                                        </div>
                                    </Popover>}
                                >
                                    <div className="w-full relative mx-1 mb-2">
                                        <label className="block  mb-2   text-sm font-medium">Votre nom complet</label>
                                        <input
                                            value={nomcomplet}
                                            ref={elemenRefnomcomplet}
                                            onChange={(e) => Getnomcomplet(e.target.value)}
                                            className={`w-full  border  outline-none   rounded-md p-2.5  bg-transparent  border-gray-300 ${nom_médicamenAnimate} focus:border-[#AF6E04]`}
                                            placeholder="Nom complet" />
                                    </div>
                                </Whisper>
                                <Whisper
                                    placement="bottomStart"

                                    open={telMessage}
                                    speaker={<Popover>
                                        <div className='text-red-700'>
                                            Le  numéro de watsapp  est obligatoire !!
                                        </div>
                                    </Popover>}
                                >
                                    <div className="w-full mx-1 relative mb-2 ">
                                        <label className="block  mb-2 text-sm font-medium">Votre watsapp</label>
                                        <input
                                            type='tel'
                                            value={tel}
                                            ref={elemenReftel}
                                            onChange={(e) => Gettel(e.target.value)}
                                            className={`w-full  border  outline-none focus:border-[#AF6E04]  rounded-md p-2.5  bg-transparent  border-gray-300 ${telAnimate}`}
                                            placeholder="Numéro de watsapp" />

                                    </div>
                                </Whisper>
                                <Whisper
                                    placement="bottomStart"

                                    open={emailMessage}
                                    speaker={<Popover>
                                        <div className='text-red-700'>{informationEmail}</div>
                                    </Popover>}
                                >
                                    <div className="w-full mx-1 relative mb-2 ">
                                        <label className="block  mb-2 text-sm font-medium">Votre email</label>
                                        <input
                                            value={email}
                                            ref={elemenRefemail}
                                            onChange={(e) => Getemail(e.target.value)}
                                            className={`w-full  border  outline-none focus:border-[#AF6E04]  rounded-md p-2.5  bg-transparent  border-gray-300 ${emailAnimate}`}

                                            placeholder="Email" />
                                    </div>
                                </Whisper>
                                <Whisper
                                    placement="bottomStart"
                                    open={categorieMessage}
                                    speaker={<Popover>
                                        <div className='text-red-700'>
                                            Précisez le service d'avocat  que vous souhaitez !!
                                        </div>
                                    </Popover>}
                                >
                                    <div className="w-full mx-1 relative mb-2 ">
                                        <label className="block  mb-2 text-sm font-medium">Article</label>
                                        <select
                                            value={categorie}
                                            ref={elemenRefcategorie}
                                            onChange={(e) => Getcategorie(e.target.value)}
                                            placeholder="Prix unitaire"
                                            className={`w-full  border  outline-none focus:border-[#AF6E04]  rounded-md p-2.5  bg-transparent  border-gray-300 ${categorieAnimate}`}
                                        >
                                            <option hidden value=''>Sélectionne l'article</option>
                                            {article.map(service => (
                                                <option key={service.id} className='text-black' value={service.titre}>{service.titre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Whisper>


                            </div>
                            <Whisper
                                placement="bottomStart"

                                open={descriptionMessage}
                                speaker={<Popover>
                                    <div className='text-red-700'>
                                        Déscription des besoins   est obligatoire !!
                                    </div>
                                </Popover>}
                            >
                                <div className="w-full relative mt-4 mb-2">
                                    <label htmlFor="company" className="block mb-1 text-sm font-medium ">Déscription des besoins</label>
                                    <textarea
                                        value={description}
                                        ref={elemenRefdescription}
                                        onCopy={(e) => Getdescription(e.target.value)}
                                        onInput={(e) => Getdescription(e.target.value)}
                                        onChange={(e) => Getdescription(e.target.value)}
                                        className={`w-full  border  outline-none focus:border-[#AF6E04] min-h-[15em]  rounded-md p-2.5  bg-transparent  border-gray-300 ${descriptionAnimate}`}
                                        placeholder="description"
                                    >

                                    </textarea>
                                </div>
                            </Whisper>

                            <div className=" flex justify-end  w-full">
                                {boutLoading ? (
                                    <>
                                        <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-[#AF6E04]    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Envoyer message' className='pointer-events-none' />
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1} />
                                            </div>
                                        </button>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-[#AF6E04]   p-2 rounded  text-white">
                                        <input type="submit" id="send" value="Envoyer message" className='cursor-pointer'></input>
                                    </label>
                                </>)}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Remerciement />
        </div>

    )
}

export default DemandArticle