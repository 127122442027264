import React, { useEffect, useRef, useState } from 'react'
import Remerciement from '../HomeVisitor/Remerciement'
import { useTheme } from '../UseContext/ThemeContext';
import ScrollReveal from 'scrollreveal'
import axios from 'axios';
import { Accordion } from 'rsuite';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
//import { FaAngleDoubleDown } from 'react-icons/fa';
function Service() {

    const [services, setservices] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const fetchservices = async () => {
        axios.get("https://hathaproject.burundientempsreel.com/service/AllforClients")
            .then(response => {
                setservices(response.data);
                setisLoading(false)

            })
            .catch(error => {
                console.error("Error fetching services:", error.message);
                setisLoading(false)
            });
    };
    useEffect(() => {
        fetchservices();
    }, []);

    const { isMargin, dataServiceHomme } = useTheme();

    const rapide = useRef(null);
    const efficace = useRef(null);
    const sécurisé = useRef(null);
    const transparent = useRef(null);
    const expérimenté = useRef(null);
    const service = useRef(null);

    useEffect(() => {
        ScrollReveal().reveal(service.current, {
            duration: 1000,
            origin: 'top',
            distance: '30%',
            delay: 20,
            easing: 'ease',
            reset: true
        });

        ScrollReveal().reveal(rapide.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '30%',
            delay: 20,
            easing: 'ease',
            reset: true
        });

        ScrollReveal().reveal(efficace.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '60%',
            delay: 40,
            easing: 'ease',
            reset: true
        });
        ScrollReveal().reveal(sécurisé.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '90%',
            delay: 60,
            easing: 'ease',
            reset: true
        });
        ScrollReveal().reveal(transparent.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '120%',
            delay: 80,
            easing: 'ease',
            reset: true
        });
        ScrollReveal().reveal(expérimenté.current, {
            duration: 1000,
            origin: 'bottom',
            distance: '150%',
            delay: 100,
            easing: 'ease',
            reset: true
        });
    }, []);

    const [mobile, GetMobile] = useState(window.innerWidth < 1164)
    const [mobile1, GetMobile1] = useState(window.innerWidth < 564)
    const [mobile2, GetMobile2] = useState(window.innerWidth < 458)
    const [mobile3, GetMobile3] = useState(window.innerWidth < 398)

    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 1164)
            GetMobile1(window.innerWidth < 564)
            GetMobile2(window.innerWidth < 458)
            GetMobile3(window.innerWidth < 398)
        }
        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    const [activeKey, SetActiveKey] = useState(dataServiceHomme)

    console.log(dataServiceHomme)
    return (
        <div >
            {isLoading && <SpinnerDemarage />}
            <div className='relative'>
                <div className={`relative  overflow-hidden ${mobile2 ? 'h-[33vh]' : mobile1 ? 'h-[40vh]' : 'h-[35vh]'} `}>
                    <img src='../images/service.webp' className='w-full h-full object-cover' alt='   ' />
                    <div className={`absolute flex flex-col w-full items-center  ${mobile ? 'top-4' : 'top-10'} left-2`}>
                        <div ref={service} className={`stroketext1 ${isMargin ? 'text-[2.5em]' : mobile2 ? 'text-[30px]' : 'text-[60px]'} `}>Nos services sont</div>
                        <div className={`w-full  flex flex-wrap justify-around  ${isMargin ? 'text-[17px]' : mobile2 ? 'text-[20px] stroketext1 ' : 'text-[30px]'}`}>
                            <div ref={rapide} className={`stroketext12 text-nowrap ${isMargin ? 'text-[17px]' : mobile2 ? 'text-[20px] stroketext1 ' : 'text-[30px]'}`}>Plus rapides</div>
                            <div ref={efficace} className={`stroketext12 text-nowrap ${isMargin ? 'text-[17px]' : mobile2 ? 'text-[20px] stroketext1 ' : 'text-[30px]'}`}>Plus efficaces</div>
                            <div ref={sécurisé} className={`stroketext12 text-nowrap ${isMargin ? 'text-[17px]' : mobile2 ? 'text-[20px] stroketext1 ' : 'text-[30px]'}`}>Plus confidentiels</div>
                            <div ref={expérimenté} className={`stroketext12 text-nowrap ${isMargin ? 'text-[17px]' : mobile2 ? 'text-[20px] stroketext1 ' : 'text-[30px]'} `}>Plus expérimentés </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-10 p-6'>
                <div className={`w-full  mb-4 hidden sm:text-[15px] ${isMargin ? 'text-[11px] ' : 'text-[13px] '}`}>
                    Parcourez nos services plus très intéressantes à vous
                </div>
                <div>
                    <div className='sm:ml-5 mt-5'>
                        <div className='text-[#AF6E04] text-[20px] sm:text-[23px]'>Voir nos services</div>
                        <div>
                            <div>
                                {services.map((data, index) => (
                                    <Accordion data-position={index} key={index} activeKey={activeKey} className={` ${index > 0 ? 'border-t-[0.5px]' : ''}  border-gray-400 rounded-[0px]`} defaultExpanded onSelect={() => SetActiveKey(index)}>
                                        <Accordion.Panel header={
                                            <div className={`font-bold text-nowrap text-ellipsis overflow-hidden  ${mobile ? mobile2 ? 'text-[11px]' : 'text-[15px]' : 'text-[18px]'}`}>{data && data.nom}</div>

                                        } eventKey={index} >
                                            <div className='w-full mt-4'>
                                                <div className='text-[#AF6E04] pl-2 text-[15px]'>Publié ,le {data && data.createdAt && new Date(data.createdAt).toLocaleDateString()}</div>
                                                <div className={`pl-2 text-[11px] sm:text-[12px] mt-3 whitespace-break-spaces leading-8`}>
                                                    {data && data.description}
                                                </div>
                                            </div>
                                        </Accordion.Panel>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Remerciement />
                </div>
            </div>
        </div>
    )
}

export default Service