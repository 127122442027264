/* eslint-disable react/jsx-no-target-blank */

import React, { useEffect, useState } from 'react'
import { useTheme } from '../UseContext/ThemeContext';

function Whate() {
    const [mobile1, Setmobile1] = useState(window.innerWidth < 804);
    const { isMargin } = useTheme()

    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 804)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])




    return (
        <div>
            <div className={`w-full flex flex-col ${isMargin ? 'mt-2' : 'mt-3'}  items-center`}>
                <div className="w-[95%]  flex flex-col items-end rounded-xl my-3 shadow-2xl p-3">
                    <div className={`text-center ${isMargin ? 'text-[20px]' : mobile1 ? 'text-[22px]' : 'text-[40px]'} w-full  text-[#AF6E04] strockText border-b pb-2`}>
                        QUI SOMMES-NOUS ?
                    </div>
                    <div className={`${isMargin ? 'text-[15px]' : mobile1 ? 'text-[18px]' : 'text-[30px]'} sm:my-1 text-center`}>
                        Le Cabinet HATHA est un Cabinet de Conseil Spécialisé en matière de Développement et Organisation d’Entreprises, de Droit et des Affaires.
                    </div>
                </div>
            </div>

            <div className={`w-full flex flex-col ${isMargin ? 'mt-2' : 'mt-3'} items-center`}>
                <div className="w-[95%]  flex flex-col items-end rounded-xl my-3 shadow-2xl p-3">
                    <div className={`text-center ${isMargin ? 'text-[20px]' : mobile1 ? 'text-[22px]' : 'text-[40px]'} w-full  text-[#AF6E04] strockText border-b pb-2`}>
                        Pourquoi le Cabinet HATHA ?
                    </div>
                    <div className={`${isMargin ? 'text-[15px]' : mobile1 ? 'text-[18px]' : 'text-[30px]'} sm:my-1 text-center`}>
                        L’Objectif Primordial du Cabinet HATHA est de contribuer à améliorer la vie du plus grand nombre grâce à notre expertise, notre savoir-faire et notre intégrité. Le Cabinet HATHA aide ses Clients à relever leurs défis et à faire face à leurs enjeux pour le développement de leurs entreprises.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whate