import React from 'react'
import SlideAdmin from '../SlideAdmin/SlideAdmin'
import AjouterDocument from './AjouterDocument'

function AjouterDocumentComponent() {
    return (
        <div className='flex w-full h-full'>
            <SlideAdmin />
            <AjouterDocument />
        </div>
    )
}

export default AjouterDocumentComponent
