/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

// Créer un contexte pour gérer le thème
const ThemeContext = createContext();

// Créer un provider pour le contexte du thème
export const ThemeProvider = ({ children }) => {

    // '''''''''''''''''''''''''''''''''''''''''''''''' Dark Mode Admin et Visteur''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
    const triggerRef = useRef();
    const close = () => triggerRef.current && triggerRef.current.close();
    //visiteur avec evenement onClick
    const [isDark, setIsDark] = useState('light');
    const toggleDarkMode = () => {
        const newDarkMode = isDark === 'dark' ? 'light' : 'dark';
        setIsDark(newDarkMode);
        localStorage.setItem('isDark', newDarkMode);
        close()
    };

    useEffect(() => {
        const storedValue = localStorage.getItem('isDark');
        if (storedValue) {
            setIsDark(storedValue);
        }
    }, [])

    //pour adimin avec evenement onChange dark
    const [isDarkadimin, GetisDarkadimin] = useState('light')
    const handleChange = (value) => {
        GetisDarkadimin(value);
        close()
        localStorage.setItem('isDark', value);
    };

    useEffect(() => {
        const storedValue = localStorage.getItem('isDark')
        if (storedValue) {
            GetisDarkadimin(storedValue);
        }
    }, [isDark]);


    //.................................Pour obtenir le valeur de Service et Publicite de Home page en son onglet appropie

    const [dataServiceHomme, setDataServiceHomme] = useState(null);
    const [datapublicitesHomme, setDatapublicitesHomme] = useState(null);
    const [dataArticleHomme, setDataArticleHomme] = useState(null);

    // ............................login................
    const [isLogin, SetIsLogin] = useState(false);
    const [isAdmin, SetisAdmin] = useState(false)

    // ........... password........ 
    const [forgetPass, setForgetPass] = useState(false);
    const [isopnedChangeP, setIsOpenedChangeP] = useState(false)
    const [isOtpCode, setIsOtpCode] = useState(false)
    const [changePassoword, setchangePassoword] = useState(false)

    //  ................... basculer vers le site administrateurs sans deconnexion...................
    const [isGoSite, SeisGoSite] = useState(false)

    // .....isMarge....
    const [isMargin, SetMargin] = useState(true)

    // .....scroolTop....................
    const elemetScroll = useRef(null)
    const [isOpen, GetIsOpen] = useState(false)
    const [isScroll, SetisScroll] = useState(false)
    const [getWidth, SetgetWidth] = useState(0)
    const [mobile, Setmobile] = useState(window.innerWidth < 890);
    const [open, Setopen] = useState(false)



    useEffect(() => {
        const hundleSize = () => {
            Setmobile(window.innerWidth < 890)
            Setopen(false)
            GetIsOpen(false)
        }
        const hundleClick = () => {
            Setopen(false)
            GetIsOpen(false)
        }

        const hundleScroll = (e) => {
            const value = e.target.scrollTop
            GetIsOpen(false)
            if (value > 100) {
                SetisScroll(true)
            } else {
                SetisScroll(false)
            }
            Setopen(false)
            SetgetWidth(value / ((e.target.scrollHeight) - (e.target.clientHeight)))
        }



        if (elemetScroll.current) {
            elemetScroll.current.addEventListener('scroll', hundleScroll)
        }
        window.addEventListener('resize', hundleSize)
        window.addEventListener('click', hundleClick)
        return () => {
            window.removeEventListener('resize', hundleSize)
            window.removeEventListener('click', hundleClick)
            if (elemetScroll.current) {
                elemetScroll.current.removeEventListener('scroll', hundleScroll)
            }

        }
    }, [elemetScroll.current])


    useEffect(() => {
        setTimeout(() => {
            if (elemetScroll.current) {
                elemetScroll.current.style.scrollBehavior = 'smooth';
            }

        }, 0);
    }, []);



    const HundlwScrollTop = () => {
        elemetScroll.current.scrollTop = 0
        elemetScroll.current.style.scrollBehavior = 'smooth'
    }



    const [visitor, GetVisitor] = useState(0)
    return (
        <ThemeContext.Provider
            value={{
                isDark,
                toggleDarkMode,
                triggerRef,
                isopnedChangeP,
                setIsOpenedChangeP,
                forgetPass,
                setForgetPass,
                close,
                handleChange,
                isDarkadimin,
                setDataServiceHomme,
                dataServiceHomme,
                setDatapublicitesHomme,
                datapublicitesHomme,
                dataArticleHomme, setDataArticleHomme,
                SetIsLogin,
                isLogin,
                isOtpCode,
                setIsOtpCode,
                changePassoword,
                setchangePassoword,
                isGoSite,
                SeisGoSite,
                isAdmin,
                SetisAdmin,
                isMargin,



                elemetScroll,
                isOpen, GetIsOpen,
                isScroll, SetisScroll,
                getWidth, SetgetWidth,
                mobile, Setmobile,
                open, Setopen,
                HundlwScrollTop,

                visitor, GetVisitor
            }}>
            {children}
        </ThemeContext.Provider>
    );
};


export const useTheme = () => {
    return useContext(ThemeContext);
};
