import React, { useEffect, useRef, useState } from 'react'
import ScrollReveal from 'scrollreveal'
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
import { useTheme } from '../UseContext/ThemeContext';
function Sloga() {
    const [mobile2, Setmobile2] = useState(window.innerWidth < 364);
    const [mobile1, Setmobile1] = useState(window.innerWidth < 586);
    const [mobile, Setmobile] = useState(window.innerWidth < 805);
    const { isMargin } = useTheme()

    useEffect(() => {
        const hundleSize = () => {
            Setmobile2(window.innerWidth < 364)
            Setmobile(window.innerWidth < 805)
            Setmobile1(window.innerWidth < 586)

        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const revealRefWhite = useRef(null);

    useEffect(() => {

        ScrollReveal().reveal(revealRefWhite.current, {
            duration: 1000,
            origin: 'left',
            distance: '30%',
            delay: 200,
            easing: 'ease',
            reset: true
        });

    }, []);

    const [loadings, Setloadings] = useState(true)

    return (
        <div className={`w-full  bg-transparent relative overflow-hidden`}>
            {loadings && <SpinnerDemarage />}
            <div className='w-full h-full '>
                <img onLoad={() => Setloadings(false)} className={`w-full h-full   animation bg-white`} alt='   ' src='images/Droit-penaldes-affaires.jpg' />
                <div ref={revealRefWhite} className='absolute   w-full h-full flex items-center justify-center top-0 left-0'>
                    <div className={`font-serif text-center px-6 strockText
                     ${isMargin ? mobile2?'text-[2em]': 'text-[2.5em]' : mobile ? mobile1 ? mobile2 ? 'text-[2em]' : 'text-[2.5em]' : 'text-[3em] ' : 'text-[5em]'}
                      `}>
                        Bienvenue au Cabinet HATHA
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Sloga
