/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
import { useTheme } from '../UseContext/ThemeContext';
import { Accordion, Popover, Whisper } from 'rsuite';
import { useNavigate } from 'react-router-dom';
//import { FaAngleDoubleDown } from 'react-icons/fa';
const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function DocumentVisit() {
    const [article, setArticle] = useState([]);
    const { isMargin, dataArticleHomme } = useTheme()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [mobile, setMobile] = useState(window.innerWidth < 800);
    const [mobile1, setMobile1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobile2] = useState(window.innerWidth < 627);
    const [mobile3, setMobile3] = useState(window.innerWidth < 443);
    const [mobile4, setMobile4] = useState(window.innerWidth < 504);
    const [isLoading, setIsLoading] = useState(true);
    const [redFile, setRedFile] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [dataServiceClicked, setDataServiceClicked] = useState(null);


    const fetchArticle = async () => {
        try {
            const response = await axios.get("https://hathaproject.burundientempsreel.com/article/all", {
                params: { page: currentPage, size: pageSize }
            });
            setArticle(response.data.articles);
            setTotalPages(response.data.totalPages);
            if (!dataServiceClicked && response.data.articles.length > 0) {
                setDataServiceClicked(response.data.articles[0]);
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching article:", error);
            setTotalPages(0);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchArticle();
    }, [currentPage, pageSize]);

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 800);
            setMobile1(window.innerWidth < 1178);
            setMobile2(window.innerWidth < 627);
            setMobile3(window.innerWidth < 443);
            setMobile4(window.innerWidth < 504);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const generatePageNumbers = () => {
        const maxPagesToShow = 5;
        const pages = [];
        let startPage = 3;
        let endPage = totalPages;

        if (totalPages > maxPagesToShow) {
            const offset = Math.floor(maxPagesToShow / 2);
            startPage = currentPage - offset;
            endPage = currentPage + offset;

            if (startPage < 1) {
                startPage = 3;
                endPage = maxPagesToShow;
            } else if (endPage > totalPages) {
                endPage = totalPages;
                startPage = totalPages - maxPagesToShow + 1;
            }
        }

        if (startPage > 3) {
            pages.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(5)}
                    className="py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg"
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(<span key="dots1">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={`py-2 px-3 mx-1 font-semibold rounded-lg ${currentPage === i ? "bg-gray-700 text-white" : "hover:bg-gray-300 bg-gray-200 text-gray-800"}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<span key="dots2">...</span>);
            }
            pages.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    className="py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg"
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    const updateDataService = (newValue) => {
        setDataServiceClicked(newValue);
        localStorage.setItem('data', JSON.stringify(newValue));
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleLoadStart = () => {
        setLoading(true);
        setError(null);
    };

    const handleLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoading(false);
    };

    const handleLoadError = (error) => {
        setError(error);
        setLoading(false);
    };
    const navig = useNavigate()
    const [activeKey, SetActiveKey] = useState(dataArticleHomme)
    return (
        <div className="w-full flex mt-1 justify-center flex-col items-center">
            {isLoading && <SpinnerDemarage />}
            <div className='p-2 w-full'>
                <div className='w-full  h-max'>
                    <div className={`font-bold  text-[#AF6E04] text-xl mb-4`}>Nos articles</div>
                </div>
                <div className="w-full rounded-lg flex flex-col mt-[20px] justify-center items-center">
                    <div className={`flex w-full ${isMargin ? 'flex-col' : mobile1 ? 'flex-col' : ''} p-2`}>

                        {article.map((data, index) => (
                            <Accordion data-position={index} key={index} activeKey={activeKey} className={` ${index > 0 ? 'border-t-[0.5px]' : ''}  border-gray-400 rounded-[0px]`} defaultExpanded onSelect={() => SetActiveKey(index)}>
                                <Accordion.Panel header={
                                    <div className={`font-bold text-nowrap text-ellipsis overflow-hidden  ${mobile ? mobile2 ? 'text-[11px]' : 'text-[15px]' : 'text-[18px]'}`}>{data && data.titre}</div>

                                } eventKey={index}>
                                    <div className='w-full mt-4'>
                                        <div className={`flex border relative ${mobile1 ? data && data.file.toLowerCase().endsWith('.pdf') ? 'h-auto' : 'h-auto' : 'h-auto'}  flex-col w-full items-center rounded-lg`}>
                                            {data && data.file.toLowerCase().endsWith('.pdf') ? (
                                                <>
                                                    <Document
                                                        file={`https://hathaproject.burundientempsreel.com/uploads/article/${data.file}`}
                                                        onLoadStart={handleLoadStart}
                                                        onLoadSuccess={handleLoadSuccess}
                                                        onLoadError={handleLoadError}
                                                        className="pdfViewer"

                                                    >
                                                        {Array.from({ length: 3 }, (_, index) => (
                                                            <Page
                                                                key={`page_${index + 1}`}
                                                                pageNumber={index + 1}
                                                                className="pdfPage"
                                                            />
                                                        ))}
                                                    </Document>
                                                    {error && <p className='text-red-600'>Erreur de chargement de pdf, vos connexion n'est pas stable</p>}
                                                    {loading &&
                                                        <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center bg-gray-50 spinners">
                                                            <svg className="animate-spin h-8 w-8 text-[#AF6E04]" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#AF6E04" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                                            </svg>
                                                        </div>

                                                    }
                                                </>
                                            ) : (
                                                data && data.file && (
                                                    <img
                                                        onLoad={() => setIsLoading(false)}
                                                        className='object-cover w-full h-full'
                                                        src={`https://hathaproject.burundientempsreel.com/uploads/article/${data.file}`}
                                                        alt=''
                                                    />
                                                )
                                            )}
                                            {isLoading &&
                                                <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center bg-gray-50 spinners">
                                                    <svg className="animate-spin h-8 w-8 text-[#AF6E04]" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#AF6E04" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                        <div className='flex justify-end w-full'>
                                            <div className='flex'>
                                                <Whisper
                                                    trigger="click"
                                                    placement="auto"
                                                    speaker={
                                                        <Popover>
                                                            <div className='text-[20px]'>
                                                                Demande l'autorisation de l'auteur
                                                            </div>
                                                            <div className='mt-2'>
                                                                <button onClick={() => navig("/document/demand")} className='px-3 py-1 border-[#AF6E04] rounded border'>
                                                                    Vos besoin
                                                                </button>
                                                            </div>
                                                        </Popover>
                                                    }
                                                >
                                                    <div className='mx-2 rounded border-[#AF6E04] text-[#AF6E04] px-4 py-2 border  cursor-pointer m-3'>
                                                        Ouvrir
                                                    </div>
                                                </Whisper>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Panel>
                            </Accordion>
                        ))}

                        {/* 
                        <div className={`flex flex-col items-center ${isMargin ? 'w-full' : mobile1 ? 'w-full' : 'w-[80%] '}`}>
                            <div className="grid grid-cols-1 w-full gap-4">

                                <div className="w-full rounded-lg">
                                    <div className={`font-bold  first-letter:uppercase ${isMargin ? 'text-xl' : 'text-3xl'} mb-1`}>
                                        {dataServiceClicked && dataServiceClicked.titre}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`${isMargin ? 'w-full' : mobile1 ? 'w-full' : 'w-[20%]'} mt-5`}>
                            <div className='flex text-gray-500 m-1'>Les articles les plus récents</div>
                            <div className={`${mobile1 ? 'overflow-hidden h-auto' : 'overflow-x-hidden overflow-y-auto h-[80vh]'} pl-5`}>
                                {article && article.map((data, index) => (
                                    dataServiceClicked && dataServiceClicked.titre === data.titre ?
                                        <button disabled key={index} className='flex text-[#AF6E04] transition-all my-5 text-[18px]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={mobile3 ? '16' : '25'} height={mobile3 ? '16' : '25'} fill="currentColor" className="bi bi-eye-fill mr-4" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                            </svg>
                                            <div className={`${isMargin ? 'text-[12px]' : mobile3 ? 'text-[15px]' : ''} text-nowrap text-ellipsis overflow-hidden`} key={index}>{data.titre}</div>
                                        </button>
                                        :
                                        <a onClick={() => updateDataService(data)} key={index} className='flex cursor-pointer hover:border-l-4 hover:text-gray-400 border-[#AF6E04] transition-all my-5 text-[18px]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={mobile3 ? '16' : '25'} height={mobile3 ? '16' : '25'} fill="currentColor" className="bi bi-arrow-right mr-4" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                            </svg>
                                            <div className={`${isMargin ? 'text-[12px]' : mobile3 ? 'text-[15px]' : ''} text-nowrap text-ellipsis overflow-hidden`} key={index}>{data.titre}</div>
                                        </a>
                                ))}
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <div className="w-[25em] h-full flex justify-around items-center p-2">
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className="py-2 px-4 bg-[#00800023] hover:bg-[#008000a1] text-[#AF6E04] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                                    >
                                        Précédent
                                    </button>
                                    <div className="flex flex-wrap ">{generatePageNumbers()}</div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className="py-2 px-4 bg-[#00800023] hover:bg-[#008000a1] text-[#AF6E04] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                                    >
                                        Suivant
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentVisit;
