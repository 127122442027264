/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function NotFind() {
    const [imageLoading, SetImageLoading] = useState(true)
    return (
        <div className={`fixed w-full select-none cursor-default flex flex-col items-center justify-center  h-full bg-white  top-0 left-0  z-[100]`}>
            <div className="flex flex-col items-center mr-1">
                <div className="w-full flex justify-center items-center">
                    <div className="h-max w-[20em]  transition-all  relative overflow-hidden ml-3 rounded ">
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src={`https://hathaproject.burundientempsreel.com/uploads/Logo/logoNewHaths.jpg`} alt="" className='h-full w-full  object-contain object-center' />
                    </div>
                </div>
            </div>
            <div className='flex justify-start text-[#AF6E04] font-bold  items-center flex-col'>
                <div className='text-[6em]'>404</div>
                <div>
                    Page non trouve !!
                </div>
            </div>
            <div className='flex w-full justify-center items-center mt-10 flex-col'>
                <Link to='/' className="text-gray-600 font-serif text-[20px] sm:text-[30px]">Retourner à  la page d'acceuil</Link>
            </div>
        </div>
    )
}

export default NotFind;
