/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Popover, Whisper } from 'rsuite';
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../../Visiteur/FootentContent/Footer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { decryptData } from '../../../encryptionModule';
import { Document, Page, pdfjs } from 'react-pdf';
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage';
import NavBarDocument from './NavBarDocument';
const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ModifierDocument() {
    const [boutLoading, setboutLoading] = useState(false);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [uploadPdfAnimate, GetuploadPdfAnimate] = useState('')
    const [uploadPdfMessage, GetuploadPdfMessage] = useState(false)
    const elemenRefuploadPdf = useRef(null)
    const [Titre, GetTitre] = useState('');
    const [TitreMessage, GetTitreMessage] = useState(false);
    const [nom_médicamenAnimate, GetAnimate] = useState('');
    const elemenRefTitre = useRef(null);
    const [loadings, Setloadings] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const { id } = useParams();
    const articleId = decryptData(id);
    const [orderfile, setOrderFile] = useState('');

    const onFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setOrderFile('')
        setFile(selectedFile);
    };

    useEffect(() => {
        axios.get(`https://hathaproject.burundientempsreel.com/article/OneById/${articleId}`)
            .then(response => {
                const { titre, file } = response.data;
                GetTitre(titre);
                setOrderFile(file);
                Setloadings(false);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération du article :", error);
                Setloadings(false);
            });
    }, [articleId]);

    const HundlesSend = e => {
        e.preventDefault();
        if (Titre.trim() === "") {
            GetTitreMessage(true);
            GetAnimate('animate__animated animate__shakeX border border-red-500');
            setTimeout(() => {
                GetTitreMessage(false);
                GetAnimate('');
            }, 4000);
            elemenRefTitre.current && elemenRefTitre.current.focus();
            return false;
        } else {
            setboutLoading(true);
            const formData = new FormData();
            formData.append("titre", Titre);
            formData.append("file", file);

            axios.put(`https://hathaproject.burundientempsreel.com/article/modifier/${articleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toast.success('Enregistrement de document effectué avec succès !');
                    navigate("/documents");
                    setboutLoading(false);
                })
                .catch(error => {
                    setboutLoading(false);
                    if (error.response) {
                        const errorMessage = error.response.data.error;
                        if (Array.isArray(errorMessage) && errorMessage.length > 0) {
                            toast.error(errorMessage[0]);
                        } else {
                            toast.error('Une erreur est survenue lors de la modification du document.');
                        }
                    } else {
                        toast.error('Une erreur est survenue lors de la modification du document.');
                        console.error('Erreur inattendue :', error.message);
                    }
                });
        }
    };

    const [mobile, GetMobile] = useState(window.innerWidth < 1133);
    const [mobile3, GetMobile3] = useState(window.innerWidth < 342);
    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 1133);
            GetMobile3(window.innerWidth < 342);
        };

        window.addEventListener('resize', HundleSize);
        return () => window.removeEventListener('resize', HundleSize);
    }, []);

    return (
        <div className='w-full'>
            <NavBarDocument />
            <div className="flex items-center w-full justify-between">
                <Link to='/documents' className="w-8 h-8 flex justify-center items-center text-[#AF6E04]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </Link>
            </div>
            <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[87vh]' : 'h-[79vh]'}`}>
                <div className="  flex flex-col items-center min-h-[79vh] rounded-lg shadow-lg w-[99%] ">
                    <div className="flex flex-col w-full  items-center justify-center p-2">
                        <div className='w-full flex justify-center'>
                            <div>
                                <div className="uppercase text-[17px] sm:text-[25px] mt-3 font-semibold">Modification d'un  document</div>
                            </div>
                        </div>
                        <div className="w-full  rounded-lg   h-full ">
                            <div className="p-3  overflow-y-auto overflow-x-hidden">
                                <form onSubmit={HundlesSend} className='w-full'>
                                    <div className={`w-full flex mt-4 ${mobile ? 'flex-col gap-4' : ''} items-center`}>
                                        <Whisper
                                            placement="bottomStart"
                                            open={TitreMessage}
                                            speaker={<Popover>
                                                <div className='text-red-700'>
                                                    Le titre du docment est obligatoire !!
                                                </div>
                                            </Popover>}
                                        >
                                            <div className="w-full relative mx-1 mb-2">
                                                <label className="block  mb-2   text-sm font-medium">Titre du document</label>
                                                <input
                                                    value={Titre}
                                                    ref={elemenRefTitre}
                                                    onChange={(e) => GetTitre(e.target.value)}
                                                    className={`w-full  border  outline-none   rounded-md p-2.5  bg-transparent  border-gray-300 ${nom_médicamenAnimate} focus:border-[#AF6E04]`}
                                                    placeholder="Titre du document"
                                                />
                                            </div>
                                        </Whisper>
                                    </div>
                                    <Whisper
                                        placement="bottomStart"
                                        open={uploadPdfMessage}
                                        speaker={<Popover>
                                            <div className='text-red-700'>
                                                Le document est obligatoire !!
                                            </div>
                                        </Popover>}
                                    >
                                        <div className="w-full mt-4 relative mx-1 mb-2">
                                            <label htmlFor='file'
                                                className={`w-full  border  outline-none  block cursor-pointer  rounded-md p-2.5  bg-transparent  border-gray-300 ${uploadPdfAnimate} focus:border-[#AF6E04]`}
                                            >Parcourir</label>
                                            <input
                                                type='file' accept='.pdf,.png,.jpg,.jpeg'
                                                hidden
                                                id='file'
                                                ref={elemenRefuploadPdf}
                                                onChange={onFileChange}
                                                placeholder=" Votre  nom complet ou de la societe"
                                            />
                                        </div>
                                    </Whisper>
                                    {file && (
                                        <div className="mt-4">
                                            {file.type === 'application/pdf' ? (
                                                <Document
                                                    file={file}
                                                    className="border border-gray-300"
                                                >
                                                    <Page pageNumber={1} className="p-4" />
                                                </Document>
                                            ) : (
                                                <img src={URL.createObjectURL(file)} alt='Uploaded' className="max-w-full h-auto border border-gray-300 rounded-md shadow-sm" />
                                            )}
                                        </div>
                                    )}
                                    {orderfile &&
                                        <>
                                            <div className='p-2'>
                                                {orderfile && orderfile.toLowerCase().endsWith('.pdf') ? (
                                                    <Document
                                                        file={`https://hathaproject.burundientempsreel.com/uploads/article/${orderfile}`}
                                                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                                                        className="pdfViewer"
                                                    >
                                                        {[...Array(numPages)].map((_, index) => (
                                                            <Page
                                                                key={`page_${index + 1}`}
                                                                pageNumber={index + 1}
                                                                className="pdfPage"
                                                            />
                                                        ))}
                                                    </Document>
                                                ) : (
                                                    <img src={`https://hathaproject.burundientempsreel.com/uploads/article/${orderfile}`} alt="Document" className="contractImage" />
                                                )}
                                                <p>Nombre de pages : {numPages}</p>
                                            </div>
                                        </>
                                    }
                                    <div className=" flex justify-end  w-full">
                                        {boutLoading ? (
                                            <>
                                                <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-[#AF6E04]    p-2 rounded  text-gray-400">
                                                    <input type="submit" id="send" value='Enregister' className='pointer-events-none' />
                                                    <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                        <FadeLoader
                                                            color="rgb(255, 255, 255)"
                                                            height={10}
                                                            margin={-9}
                                                            radius={100}
                                                            speedMultiplier={1}
                                                            width={1}
                                                        />
                                                    </div>
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <div className='flex justify-end w-full'>
                                                    <label htmlFor="send" className=" mt-3 cursor-pointer w-max   bg-[#AF6E04]   p-2 rounded  text-white">
                                                        <input type="submit" id="send" value="Modifier" className='cursor-pointer' />
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ModifierDocument;
