// import React, { useEffect, useState } from 'react'
// import Footer from '../../Visiteur/FootentContent/Footer';
// import ServiceNavBars from './ServiceNavBars';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { FadeLoader } from 'react-spinners';


// function AjoutService() {
//     const [boutLoading, setboutLoading] = useState(false);
//     const [inputFields, setInputFields] = useState([{ value: '' }]);

//     const handleChangeInput = (index, event) => {
//         const values = [...inputFields];
//         values[index].value = event.target.value;
//         setInputFields(values);
//     };

//     const handleAddFields = () => {
//         setInputFields([...inputFields, { value: '' }]);
//     };

//     const handleRemoveFields = (index) => {
//         const values = [...inputFields];
//         values.splice(index, 1);
//         setInputFields(values);
//     };
//     const navigate = useNavigate()
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const isFieldsEmpty = inputFields.some(field => field.value.trim() === '');
//         if (isFieldsEmpty) {
//             toast.info("Veuillez remplir tous les champs avant de soumettre le formulaire.");
//             return;
//         }


// const formattedData = inputFields.map(field => ({ nom: field.value }));
// setboutLoading(true)
// // Effectuez l'appel à l'API pour soumettre le formulaire
// axios.post('https://hathaproject.burundientempsreel.com/service/ajouter', formattedData)
//     .then(response => {
//         // Réinitialisez les champs du formulaire
//         setInputFields([{ value: '' }]);
//         navigate("/service")
//         // Affichez un message de succès
//         toast.success('Formulaire soumis avec succès !');
//         setboutLoading(false)
//     })
//     .catch(error => {
//         // Gérez les erreurs renvoyées par l'API
//         if (error.response) {
//             // Erreurs renvoyées par le backend
//             const errorMessage = error.response.data.error;
//             toast.error(Array.isArray(errorMessage) ? errorMessage.join('\n') : errorMessage);
//             setboutLoading(false)
//         } else {
//             // Erreur inattendue
//             toast.error('Une erreur est survenue lors de la soumission du formulaire.');
//             console.error('Erreur inattendue :', error.message);
//             setboutLoading(false)
//         }
//     });
//     };



//     const [mobile3, SetMobile3] = useState(window.innerWidth < 342)

//     useEffect(() => {
//         const hundleSize = () => {
//             SetMobile3(window.innerWidth < 342)
//         }
//         window.addEventListener('resize', hundleSize)
//         return () => {
//             window.removeEventListener('resize', hundleSize)
//         }
//     }, [])



//     return (
//         <div className='w-full'>
//            
//             <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[87vh]' : 'h-[79vh]'}`}>
//                 <div className='flex flex-col justify-start min-h-[80vh] mb-2 mt-10 w-full'>
//                     <div className=' mx-5 text-2xl w-full  '>Ajoute le services</div>
//                     <div className='w-full p-3'>
//                         <form onSubmit={handleSubmit}>
//                             {inputFields.map((inputField, index) => (
//                                 <div key={index} className='flex w-full text-center'>
//                                     <div className='w-[90%] mr-2 my-2'>
//                                         <input
//                                             type="text"
//                                             placeholder="Entrez une valeur"
//                                             value={inputField.value}
//                                             className={`w-full  border  pr-8 outline-none focus:border-[#AF6E04]  rounded-md p-2.5  bg-transparent  border-gray-200`}
//                                             onChange={event => handleChangeInput(index, event)}
//                                         />
//                                     </div>
//                                     {inputFields.length > 1 && index > 0 && (
//                                         <button type="button" className='  bg-red-400 text-white p-2 rounded  h-max mt-2' onClick={() => handleRemoveFields(index)}>
//                                             Supprimer
//                                         </button>
//                                     )}
//                                 </div>
//                             ))}
//                             <div className=''>
//                                 <button type="button" className='text-[#AF6E04]' onClick={handleAddFields}>
//                                     Ajouter un champ
//                                 </button>

//                             </div>
//                             <div className=" flex justify-end  w-full">
//                                 {boutLoading ? (
//                                     <>
//                                         <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-[#AF6E04]    p-2 rounded  text-gray-400">
//                                             <input type="submit" id="send" value='Enregister' className='pointer-events-none' />
//                                             <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
//                                                 <FadeLoader
//                                                     color="rgb(255, 255, 255)"
//                                                     height={10}
//                                                     margin={-9}
//                                                     radius={100}
//                                                     speedMultiplier={1}
//                                                     width={1}
//                                                 />
//                                             </div>
//                                         </button>
//                                     </>
//                                 ) : (<>
//                                     <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-[#AF6E04]   p-2 rounded  text-white">
//                                         <input type="submit" id="send" value="Enregister" className='cursor-pointer'></input>
//                                     </label>
//                                 </>)}
//                             </div>
//                         </form>
//                     </div>
//                 </div>

//                 <Footer />
//             </div></div>
//     )
// }

// export default AjoutService






/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';
import { FadeLoader } from 'react-spinners';
import Footer from '../../Visiteur/FootentContent/Footer';
import { useNavigate } from 'react-router-dom';
import ServiceNavBars from './ServiceNavBars';

const AjoutService = () => {
    const [boutLoading, setboutLoading] = useState(false);
    const navigate = useNavigate()
    const [blocks, setBlocks] = useState([
        { titre: '', description: '' }
    ]);

    const addBloc = () => {
        setBlocks([...blocks, { titre: '', description: '' }]);
    };

    const removeBloc = (index) => {
        const newBlocs = [...blocks];
        newBlocs.splice(index, 1);
        setBlocks(newBlocs);
    };

    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocks.length) {
        inputRefs.current = blocks.map(() => ({
            titre: React.createRef(null),
            description: React.createRef(null),
        }));
    }

    useEffect(() => {
        inputRefs.current.forEach((ref, index) => {
            if (ref.description.current) {
                ref.description.current.style.height = 'auto';
                ref.description.current.style.height = `${ref.description.current.scrollHeight}px`;
            }

        });
    }, [blocks, inputRefs.current]);


    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocks];
        newBlocs[index][field] = value;
        newBlocs[index] = { ...newBlocs[index], [field]: value };
        setBlocks(newBlocs);

        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            }, 5000);
            ref.current.focus();
        }
    };




    const validateBloc = (bloc, index) => {
        const requiredFields = ['titre', 'description'];
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(
                    <div>Le <strong>                {
                        field === 'titre' ? 'titre de service ' :

                            field === 'description' ? 'description  de service ' :
                                null}</strong> est obligatoire à block de service {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
                    }, 3000);
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }

        //Validation pour empêcher evemenement  avec le même titr
        const isSametitre = blocks.some((b, i) => i !== index && b.titre === bloc.titre);
        if (isSametitre) {
            toast.warning(<div>Vous avez déjà utilisé le titre <span className="font-bold">{bloc.titre}</span> dans le service de block  {(index + 1)}</div>);
            animateAndScrollToRef(inputRefs.current[index].titre);
            return false;
        }


        //Validation pour empêcher evemenement  avec le même description
        const isSamedescrip = blocks.some((b, i) => i !== index && b.description === bloc.description);
        if (isSamedescrip) {
            toast.warning(<div>Vous avez déjà utilisé le description <span className="font-bold">{bloc.description}</span> dans la service de block  {(index + 1)}</div>);
            animateAndScrollToRef(inputRefs.current[index].description);
            return false;
        }

        return true;
    }

    const data = blocks.map((block) => ({
        nom: block.titre,
        description: block.description,
    }))


    const handleSubmit = (e) => {
        e.preventDefault();

        for (let index = 0; index < blocks.length; index++) {
            if (!validateBloc(blocks[index], index)) {
                return;
            }
        }
        setboutLoading(true)

        axios.post('https://hathaproject.burundientempsreel.com/service/ajouter', data)
            .then(response => {
                setBlocks([{ titre: '', description: '' }]);
                navigate("/service")
                toast.success('Formulaire soumis avec succès !');
                setboutLoading(false)
            })
            .catch(error => {
                // Gérez les erreurs renvoyées par l'API
                if (error.response) {
                    // Erreurs renvoyées par le backend
                    const errorMessage = error.response.data.error;
                    toast.error(Array.isArray(errorMessage) ? errorMessage.join('\n') : errorMessage);
                    setboutLoading(false)
                } else {
                    // Erreur inattendue
                    toast.error('Une erreur est survenue lors de la soumission du formulaire.');
                    console.error('Erreur inattendue :', error.message);
                    setboutLoading(false)
                }
            });

    };


    const [mobile3, SetMobile3] = useState(window.innerWidth < 342)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile3(window.innerWidth < 342)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])




    return (
        <div className='w-full'>
            <ServiceNavBars />
            <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[87vh]' : 'h-[79vh]'}`}>
                <form onSubmit={handleSubmit} className="flex flex-col items-center mb-5">
                    <div className="rounded-lg p-2   w-[90%]  sm:p-4">
                        {blocks.map((block, index) => (
                            <div key={index} className="justify_center rounded-xl w-full p-1 sm:p-4 mb-10 border border-[#AF6E04] flex flex-col">
                                <label className="block tracking-wide text-grey-darker text-lg mb-2">Titre</label>
                                <input
                                    className="appearance-none outline-none focus:border focus:border-[#AF6E04] block bg-transparent w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                    type="text"
                                    ref={inputRefs.current[index].titre}
                                    value={block.titre}
                                    placeholder="Le titre de service est obligatoire"
                                    onChange={(event) => handleInputChange(index, 'titre', event.target.value)}
                                />
                                <label className="block tracking-wide text-grey-darker text-lg mb-2 mt-7">Description:</label>
                                <textarea
                                    className="appearance-none block min-h-[15em] resize-y bg-transparent overflow-hidden outline-none focus:border focus:border-[#AF6E04] w-full bg-grey-lighter text-grey-darker border border-gray-400 rounded  p-2 mb-1"
                                    ref={inputRefs.current[index].description}
                                    value={block.description}
                                    placeholder="La description de service est obligatoire"
                                    onChange={(event) => handleInputChange(index, 'description', event.target.value)}
                                />
                                <div className='w-full flex justify-start'>
                                    {index !== 0 && (
                                        <button type="button" className='text-red-600 font-extrabold' onClick={() => removeBloc(index)}>
                                            Supprimer
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                        <button type="button" onClick={addBloc}
                            className="bg-[#AF6E04] cursor-pointer hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded">
                            Ajouter un autre service
                        </button>
                    </div>
                    <div className=" flex justify-end  w-[95%]">

                        {boutLoading ? (
                            <>
                                <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-orange-950    p-2 rounded  text-gray-400">
                                    <input type="submit" id="send" value='Enregister' className='pointer-events-none' />
                                    <i class="bi bi-send ml-2  pointer-events-none "></i>
                                    <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                        <FadeLoader
                                            color="rgb(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        />
                                    </div>
                                </button>
                            </>
                        ) : (
                            <>
                                <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-[#AF6E04]   p-2 rounded  text-white">
                                    <input type="submit" id="send" value="Enregister" className='cursor-pointer'></input>
                                    <i class="bi bi-send ml-2 "></i>
                                </label>
                            </>
                        )}
                    </div>
                </form>
                <Footer />
            </div>
        </div>
    );
};

export default AjoutService;
