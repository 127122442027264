
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';
import { FadeLoader } from 'react-spinners';
import Footer from '../../Visiteur/FootentContent/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceNavBars from './ServiceNavBars';
import { decryptData } from '../../../encryptionModule';

const ModifierService = () => {
    const [boutLoading, setboutLoading] = useState(false);
    const navigate = useNavigate()
    const [titre, settitre] = useState("");
    const [description, setdescription] = useState("");
    const textareaRef = useRef()
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [description]);

    const { id } = useParams()
    const serviceId = decryptData(id)

    // Fonction pour récupérer les détails du service
    useEffect(() => {
        axios.get(`https://hathaproject.burundientempsreel.com/service/OneById/${serviceId}`)
            .then(response => {
                const service = response.data;
                settitre(service.nom);
                setdescription(service.description);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des détails du service :', error);
            });
    }, [serviceId]);




    const handleSubmit = (e) => {
        e.preventDefault();
        setboutLoading(true)
        const data = {
            nom: titre,
            description: description
        }
        // Envoie de la requête au backend avec Axios
        axios.put(`https://hathaproject.burundientempsreel.com/service/modifier/${serviceId}`, data)
            .then(response => {
                settitre("")
                setdescription("")
                // Gestion de la réponse du backend
                toast.success('Service modifié avec succès');
                setboutLoading(false)
                navigate("/service")
            })
            .catch(error => {
                // Gestion des erreurs
                console.error('Erreur lors de la modification du service :', error);
                toast.error('Erreur lors de la modification du service');
                setboutLoading(false);
            });

    };


    const [mobile3, SetMobile3] = useState(window.innerWidth < 342)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile3(window.innerWidth < 342)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])




    return (
        <div className='w-full'>
            <ServiceNavBars />
            <div className={`w-full overflow-y-auto  overflow-x-hidden  ${mobile3 ? 'h-[87vh]' : 'h-[79vh]'}`}>
                <form onSubmit={handleSubmit} className="flex flex-col min-h-[79vh] items-center mb-5">
                    <div className="rounded-lg p-2   w-[90%]  sm:p-4">

                        <div className="justify_center rounded-xl w-full p-1 sm:p-4 mb-10 border border-[#AF6E04] flex flex-col">
                            <label className="block tracking-wide text-grey-darker text-lg mb-2">Titre</label>
                            <input
                                className="appearance-none outline-none focus:border focus:border-[#AF6E04] block bg-transparent w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"

                                value={titre}
                                placeholder="Le titre de service est obligatoire"
                                onChange={(event) => settitre(event.target.value)}
                            />
                            <label className="block tracking-wide text-grey-darker text-lg mb-2 mt-7">Description:</label>
                            <textarea
                                ref={textareaRef}
                                className="appearance-none block  resize-none  bg-transparent overflow-hidden outline-none focus:border focus:border-[#AF6E04] w-full bg-grey-lighter text-grey-darker border border-gray-400 rounded  p-2 mb-1"

                                value={description}
                                placeholder="La description de service est obligatoire"
                                onChange={(event) => setdescription(event.target.value)}
                            />
                            <div className='w-full flex justify-start'>

                            </div>
                        </div>


                    </div>
                    <div className=" flex justify-end  w-[95%]">

                        {boutLoading ? (
                            <>
                                <button disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center  bg-orange-950    p-2 rounded  text-gray-400">
                                    <input type="submit" id="send" value='Enregister' className='pointer-events-none' />
                                    <i class="bi bi-send ml-2  pointer-events-none "></i>
                                    <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                        <FadeLoader
                                            color="rgb(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        />
                                    </div>
                                </button>
                            </>
                        ) : (
                            <>
                                <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-[#AF6E04]   p-2 rounded  text-white">
                                    <input type="submit" id="send" value="Modifier" className='cursor-pointer'></input>
                                    <i class="bi bi-send ml-2 "></i>
                                </label>
                            </>
                        )}
                    </div>
                </form>
                <Footer />
            </div>
        </div>
    );
};

export default ModifierService;



// import React, { useState, useEffect, useRef } from 'react';

// const ModifierService = () => {
//     const [text, setText] = useState('');
//     const textareaRef = useRef(null);

// useEffect(() => {
//     if (textareaRef.current) {
//         textareaRef.current.style.height = 'auto';
//         textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
//     }
// }, [text]);

//     const handleChange = (e) => {
//         setText(e.target.value);

//     };

//     return (
//         <div className='w-full bg-gray-400'>
//             <textarea
//                 ref={textareaRef}
//                 value={text}
//                 onChange={handleChange}
//                 style={{
//                     overflow: 'hidden',
//                     resize: 'none',
//                 }}
//             />
//         </div>
//     );
// };

// export default ModifierService;
