/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useTheme } from '../Visiteur/UseContext/ThemeContext';

function SpinnerDemarage() {
    const { isMargin } = useTheme()
    const [imageLoading, SetImageLoading] = useState(true)
    return (
        <div className={`${isMargin ? 'absolute' : 'fixed'} w-full  flex flex-col items-center justify-center  h-full bg-white  top-0 left-0  z-[100]`}>
            <div className="flex flex-col items-center mr-1">
                <div className="w-full flex justify-center items-center">
                    <div className="h-max max-w-[30em]  transition-all  relative overflow-hidden ml-3 rounded ">
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src={`https://hathaproject.burundientempsreel.com/uploads/Logo/logoNewHaths.jpg`} alt=" " className='h-full w-full   object-contain object-center' />
                    </div>
                </div>
            </div>
            <div className='flex w-full justify-center items-center mt-10 flex-col'>
                <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="text-gray-600 font-serif text-[13px]">Veuillez patienter...</div>
            </div>
        </div>
    )
}

export default SpinnerDemarage;
