/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import './App.css'
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "apexcharts/dist/apexcharts.css";
import 'swiper/css';
import 'rsuite/dist/rsuite.min.css';
import { publicIpv4 } from 'public-ip';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import AcceuilCompent from "./component/Visiteur/HomeVisitor/AcceuilCompent";
import NavBarsVisiteur from "./component/Visiteur/NavBars/NavBars";
import { CustomProvider, Popover, Whisper } from "rsuite";
import FootentContent from "./component/Visiteur/FootentContent/FootentContent";
import NavBarsAdmin from "./component/Admin/NavBarsAdmin/NavBarsAdmin";
import AcceuiAdminCompoent from "./component/Admin/AcceuiAdmin/AcceuiAdminCompoent";
import AdminCompteComponent from "./component/Admin/AdminCompte/AdminCompteComponent";
import AdminCompteModifierComponet from "./component/Admin/AdminCompte/AdminCompteModifierComponet";
import Registrant from "./component/Visiteur/Formaire/Registrant";
import Login from "./component/Visiteur/Formaire/Login";
import { useTheme } from "./component/Visiteur/UseContext/ThemeContext";
import ClientAdminComponent from "./component/Admin/ClientAdmin/ClientAdminComponent";

import PubliciteCompoent from "./component/Admin/Publicite/PubliciteCompoent";
import MessageComponent from "./component/Admin/Message/MessageComponent";
import ContrantComponent from "./component/Admin/Contrant/ContrantComponent";
import AjouteClientAdminComponent from "./component/Admin/ClientAdmin/AjouteClientAdminComponent";
import ModifierClientAdminComponent from "./component/Admin/ClientAdmin/ModifierClientAdminComponent";
import DetailClientAdminComponent from "./component/Admin/ClientAdmin/DetailClientAdminComponent";
import DetailPubliciteEvenetComponent from "./component/Admin/Publicite/DetailPubliciteComponent";
import NouvelContrantComponent from "./component/Admin/Contrant/NouvelContrantComponent";

import ExpireContrantComponent from "./component/Admin/Contrant/ExpireContrantComponent";
import ExpireContrantDetailComponent from "./component/Admin/Contrant/ExpireContrantDetailComponent";

import ContrantDetailComponent from "./component/Admin/Contrant/ContrantDetailComponent";
import ModifierContrantComponent from "./component/Admin/Contrant/ModifierContrantComponent";
import EditorAdminPubliciteCompoenent from "./component/Admin/Publicite/EditorAdminPubliciteCompoenent";
import AjouterPubliciteAdminComponent from "./component/Admin/Publicite/AjouterPubliciteAdminComponent";
import TachesComponent from "./component/Admin/Tache/TachesComponent";
import AjouterTachesComponenent from "./component/Admin/Tache/AjouterTachesComponenent";
import TachesModifierComponent from "./component/Admin/Tache/TachesModifierComponent";
import TacheinvalideComponent from "./component/Admin/Tache/TacheinvalideComponent";
import TachesExpireComponent from "./component/Admin/Tache/TachesExpireComponent";
import DetaillMessageComponent from "./component/Admin/Message/DetaillMessageComponent";
import ServiceComponent from "./component/Admin/Service/ServiceComponent";
import AjoutServiceComponent from "./component/Admin/Service/AjoutServiceComponent";
import CoordonneeComponent from "./component/Admin/Coordonnee/CoordonneeComponent";
import CoordonneeComponentAjout from "./component/Admin/Coordonnee/CoordonneeComponentAjout";
import CoordonneeComponentModifier from "./component/Admin/Coordonnee/CoordonneeComponentModifier";
import HoraireComponent from "./component/Admin/Horaire/HoraireComponent";
import HoraireComponentAjout from "./component/Admin/Horaire/HoraireComponentAjout";
import HoraireComponentModifier from "./component/Admin/Horaire/HoraireComponentModifier";
import SociauxComponent from "./component/Admin/Sociauxmedia/SociauxComponent";
import AjouterSociauxComponent from "./component/Admin/Sociauxmedia/AjouterSociauxComponent";
import ModifierSocialComponent from "./component/Admin/Sociauxmedia/ModifierSocialComponent";
import NotFind from "./component/PagesForm/NotFind";
import Publicite from "./component/Visiteur/Publicite/Publicite";
import ServiceVisiteur from "./component/Visiteur/Service/Service";
import DemandServer from "./component/Visiteur/DemandServer/DemandServer";
import Propos from "./component/Visiteur/Appropos/Propos";
import Forgetpassword from "./component/Visiteur/Formaire/Forgetpassword";
import OtpCode from "./component/Visiteur/Formaire/OtpCode";
import ChangePassoword from "./component/Visiteur/Formaire/ChangePassoword";
import VisiteursListComponent from "./component/Admin/VisiteursList/VisiteursListComponent";
import Actualite from "./component/Visiteur/DocumentVisit/DocumentVisit";
import DocumentsCompenent from "./component/Admin/Documents/DocumentsCompenent";
import AjouterDocumentComponent from "./component/Admin/Documents/AjouterDocumentComponent";
import ModifierDocumentComponent from "./component/Admin/Documents/ModifierDocumentComponent";
import DetailDocumentComponent from "./component/Admin/Documents/DetailDocumentComponent";
import DocumentVisit from "./component/Visiteur/DocumentVisit/DocumentVisit";
import ModifierServiceComponent from "./component/Admin/Service/ModifierServiceComponent";
import DemandArticle from "./component/Visiteur/DocumentVisit/DemandArticle";



function App() {
  const { isDark, isDarkadimin, isGoSite, isAdmin, SetisAdmin, isMargin, HundlwScrollTop, getWidth, elemetScroll, mobile, open, isScroll, Setopen, GetIsOpen } = useTheme();
  const [visit, Setvisitor] = useState(false)

  const token = localStorage.getItem("xtoks");

  useEffect(() => {
    if (token) {
      SetisAdmin(true)
    } else (
      Setvisitor(true)
    )
  }, [token]);



  useEffect(() => {
    const trackVisitor = async () => {
      try {
        const ip = await publicIpv4();
        const currentDate = new Date().toISOString().split('T')[0];

        const storedVisitors = JSON.parse(localStorage.getItem('visitors')) || [];


        const isVisitorToday = storedVisitors.some(visitor =>
          visitor.ip === ip && visitor.date === currentDate
        );

        if (!isVisitorToday) {

          const newVisitor = { ip, date: currentDate };
          storedVisitors.push(newVisitor);
          localStorage.setItem('visitors', JSON.stringify(storedVisitors));

          axios.post('https://hathaproject.burundientempsreel.com/api/visitors', newVisitor)
            .then(response => {
              // toast.success('Bienvenue sur notre site!');
            })
            .catch(error => {
              console.error('Error tracking visitor:', error);
              //toast.error('Erreur lors de l\'enregistrement du visiteur.');
            });
        }
      } catch (error) {
        console.error('Error tracking visitor:', error.message);

      }
    };

    trackVisitor();
  }, []);



  const [socialMediaList, setSocialMediaList] = useState([]);
  useEffect(() => {
    const fetchSocialMedia = async () => {
      try {
        const response = await axios.get('https://hathaproject.burundientempsreel.com/socialmedia');
        setSocialMediaList(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
        //toast.error('Erreur lors de la récupération des réseaux sociaux');
      }
    };
    fetchSocialMedia();
  }, []);



  return visit ? (
    <CustomProvider theme={isDark}>
      <Router>
        <div className={`h-screen relative overflow-hidden${isMargin ? ' w-[65em]' : ' w-full'}`}>
          <Login SetisAdmin={SetisAdmin} />
          <Forgetpassword />
          <OtpCode />
          <div
            className={`h-[3px]  ${isMargin ? 'absolute' : 'fixed'} bg-[#AF6E04] top-0   left-0 z-[2000]`}
            style={{
              width: `${getWidth * 100}%`,
            }}
          >
          </div>


          <NavBarsVisiteur HundlwScrollTop={HundlwScrollTop} />
          <div ref={elemetScroll} className={`w-full ${mobile ? ' h-[92vh]' : ' h-[85vh]'} overflow-x-hidden overflow-y-auto`}>
            <Routes>
              <Route path="/" element={<AcceuilCompent HundlwScrollTop={HundlwScrollTop} />} />
              <Route path="/Publicite" element={<Publicite />} />
              <Route path="/service" element={<ServiceVisiteur />} />
              <Route path="/demandServer" element={<DemandServer />} />
              <Route path="/document" element={<Actualite />} />
              <Route path="/document/demand" element={<DemandArticle />} />
              <Route path="/propos" element={<Propos />} />
              <Route path='/mauyfri4ehgruiewrhiweygtyutgryuwgjhdsgfdsuhftgdsyighifdguh' element={<Registrant />} />
              <Route path='*' element={<NotFind />} />
            </Routes>
            <FootentContent />
          </div>
          <div onClick={HundlwScrollTop}
            className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all absolute w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 bottom-2 bg-[#617a5c] hover:bg-[#AF6E04] duration-75 text-white z-[30]`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
              <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
            </svg>
          </div>

          <div className={`bg-[#00000052] absolute left-[1em] z-[100] ${open ? 'bottom-[1em]' : '-bottom-[100%]'}  transition-all duration-150  overflow-hidden  grid grid-cols-2 rotate-0  rounded`}>

            {socialMediaList.map((socialMedia, index) => (
              <>

                <a href={socialMedia.link} target='_blank' rel="noreferrer" className='bg-red-100  flex justify-center items-center text-red-700 hover:no-underline focus:no-underline hover:text-red-700 focus:text-red-700 cursor-pointer rounded rotate-0 p-2 m-1'>
                  <div className="text-[20px] mr-2" dangerouslySetInnerHTML={{ __html: socialMedia.icon }}></div>
                  <div>
                    {socialMedia.nom}
                  </div>
                </a>
              </>
            ))}
          </div>
          <div onClick={(e) => { GetIsOpen(false); Setopen(true); e.stopPropagation() }} className='absolute z-[99] bottom-[0.5em] rounded-full cursor-pointer text-white bg-[#AF6E04] p-2  left-[0.5em]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
            </svg>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </CustomProvider>

  ) : isAdmin ? (
    <CustomProvider theme={isDarkadimin} >
      {isGoSite ?
        <Router>
          <div className={`h-screen   relative overflow-hidden ${isMargin ? ' w-[65em]' : ' w-full'}`}>
            <Login SetisAdmin={SetisAdmin} />
            <Forgetpassword />
            <OtpCode />
            <div
              className={`h-[3px]  ${isMargin ? 'absolute' : 'fixed'} bg-[#AF6E04] top-0   left-0 z-[2000]`}
              style={{
                width: `${getWidth * 100}%`,
              }}
            >
            </div>
            <NavBarsVisiteur HundlwScrollTop={HundlwScrollTop} />
            <div ref={elemetScroll} className={`w-full ${mobile ? ' h-[92vh]' : ' h-[85vh]'} overflow-x-hidden overflow-y-auto`}>
              <Routes>
                <Route path="/" element={<AcceuilCompent HundlwScrollTop={HundlwScrollTop} />} />
                <Route path="/Publicite" element={<Publicite />} />
                <Route path="/service" element={<ServiceVisiteur />} />
                <Route path="/demandServer" element={<DemandServer />} />
                <Route path="/document" element={<Actualite />} />
                <Route path="/document/demand" element={<DemandArticle />} />
                <Route path="/propos" element={<Propos />} />
                <Route path='/mauyfri4ehgruiewrhiweygtyutgryuwgjhdsgfdsuhftgdsyighifdguh' element={<Registrant />} />
                <Route path='*' element={<NotFind />} />
              </Routes>
              <FootentContent />
            </div>
          </div>
          <div onClick={HundlwScrollTop}
            className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 bottom-2 bg-[#617a5c] hover:bg-[#AF6E04] duration-75 text-white z-[30]`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
              <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
            </svg>
          </div>
          <div className={`bg-[#00000052] fixed left-[1em] z-[100] ${open ? 'bottom-[1em]' : '-bottom-[100%]'}  transition-all duration-150  overflow-hidden  grid grid-cols-2 rotate-0  rounded`}>

            {socialMediaList.map((socialMedia, index) => (
              <>

                <a href={socialMedia.link} target='_blank' rel="noreferrer" className='bg-red-100  flex justify-center items-center text-red-700 hover:no-underline focus:no-underline hover:text-red-700 focus:text-red-700 cursor-pointer rounded rotate-0 p-2 m-1'>
                  <div className="text-[20px] mr-2" dangerouslySetInnerHTML={{ __html: socialMedia.icon }}></div>
                  <div>
                    {socialMedia.nom}
                  </div>
                </a>
              </>
            ))}
          </div>
          <div onClick={(e) => { GetIsOpen(false); Setopen(true); e.stopPropagation() }} className='fixed z-[99] bottom-[0.5em] rounded-full cursor-pointer text-white bg-[#AF6E04] p-2  left-[0.5em]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
            </svg>
          </div>
          <ToastContainer position="top-center" autoClose={3000} />
        </Router>
        :
        <Router>
          <div className={`h-screen relative overflow-hidden adminClassWidth  w-full`}>
            <div
              className={`h-[3px]  ${isMargin ? 'absolute' : 'fixed'} bg-[#AF6E04] top-0   left-0 z-[2000]`}
              style={{
                width: `${getWidth * 100}%`,
              }}
            >
            </div>
            <NavBarsAdmin />
            <Routes>
              <Route path='/' element={<AcceuiAdminCompoent />} />
              <Route path='/acceuil' element={<AcceuiAdminCompoent />} />
              <Route path='/Client' element={<ClientAdminComponent />} />
              <Route path='/Client/ajouter' element={<AjouteClientAdminComponent />} />
              <Route path='/Client/modifier/:id' element={<ModifierClientAdminComponent />} />
              <Route path='/Client/detail/:id' element={<DetailClientAdminComponent />} />

              <Route path='/tache' element={<TachesComponent />} />
              <Route path='/tache/ajouter' element={<AjouterTachesComponenent />} />
              <Route path='/tache/modifier/:id' element={<TachesModifierComponent />} />
              <Route path='/tache/cote' element={<TacheinvalideComponent />} />
              <Route path='/tache/expire' element={<TachesExpireComponent />} />

              <Route path='/Publicite' element={<PubliciteCompoent />} />
              <Route path='/Publicite/detail/:id' element={<DetailPubliciteEvenetComponent />} />
              <Route path='/Publicite/modifier/:id' element={<EditorAdminPubliciteCompoenent />} />
              <Route path='/Publicite/ajouter' element={<AjouterPubliciteAdminComponent />} />

              <Route path='/message' element={<MessageComponent />} />
              <Route path='/message/detail/:id' element={<DetaillMessageComponent />} />

              <Route path='/contrat' element={<ContrantComponent />} />
              <Route path='/contrat/detail/:id' element={<ContrantDetailComponent />} />
              <Route path='/contrat/modifier/:id' element={<ModifierContrantComponent />} />
              <Route path='/contrat/Ajouter' element={<NouvelContrantComponent />} />
              <Route path='/contrat/expire' element={<ExpireContrantComponent />} />
              <Route path='/contrat/expire/detail/:id' element={<ExpireContrantDetailComponent />} />

              <Route path='/service' element={<ServiceComponent />} />
              <Route path='/service/modifier/:id' element={<ModifierServiceComponent />} />
              <Route path='/service/ajouter' element={<AjoutServiceComponent />} />

              <Route path='/coordonne' element={<CoordonneeComponent />} />
              <Route path='/coordonne/Ajout' element={<CoordonneeComponentAjout />} />
              <Route path='/coordonne/Modifier/:id' element={<CoordonneeComponentModifier />} />

              <Route path='/horaire' element={<HoraireComponent />} />
              <Route path='/horaire/Ajout' element={<HoraireComponentAjout />} />
              <Route path='/horaire/Modifier/:id' element={<HoraireComponentModifier />} />

              <Route path='/sociauxmedia' element={<SociauxComponent />} />
              <Route path='/sociauxmedia/Ajout' element={<AjouterSociauxComponent />} />
              <Route path='/sociauxmedia/Modifier/:id' element={<ModifierSocialComponent />} />

              <Route path='/compte' element={<AdminCompteComponent />} />
              <Route path='/compte/modifier' element={<AdminCompteModifierComponet />} />

              <Route path='/listVisiteur' element={<VisiteursListComponent />} />

              <Route path='/documents' element={<DocumentsCompenent />} />
              <Route path='/documents/ajouter' element={<AjouterDocumentComponent />} />
              <Route path='/documents/modifier/:id' element={<ModifierDocumentComponent />} />
              <Route path='/documents/detail/:id' element={<DetailDocumentComponent />} />

              <Route path='*' element={<NotFind />} />

            </Routes>
          </div>
          <ToastContainer position="top-center" autoClose={3000} />
        </Router>
      }

    </CustomProvider>
  ) : null;
}

export default App;
