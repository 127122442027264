import React, { useEffect, useState } from 'react'
import Remerciement from '../HomeVisitor/Remerciement'
import { useTheme } from '../UseContext/ThemeContext'
import axios from 'axios'
import ReactPlayer from 'react-player'
import SpinnerDemarage from '../../SpinnerDemarage/SpinnerDemarage'
import { Accordion } from 'rsuite';
//import { FaAngleDoubleDown } from 'react-icons/fa'; caretAs={FaAngleDoubleDown}

function Publicite() {
    const [isloading, SetIsLoading] = useState(true)
    const { isMargin } = useState()
    const { datapublicitesHomme }=useTheme()

    const hundleLoading = () => {
        SetIsLoading(false)
    }
    const [publicites, setpublicites] = useState([]);
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://hathaproject.burundientempsreel.com/event/getAllevents`);
                setpublicites(response.data.publicites);
                SetIsLoading(false)

            } catch (error) {
                console.error('Erreur lors de la récupération des events :', error);
                SetIsLoading(false)

            }
        };

        fetchData();
    }, []);





    const [mobile, GetMobile] = useState(window.innerWidth < 655)
    const [mobile3, GetMobile3] = useState(window.innerWidth < 485)
    const [mobile2, Setmobile2] = useState(window.innerWidth < 698);


    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 655)
            GetMobile3(window.innerWidth < 485)
            Setmobile2(window.innerWidth < 698)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])




    const [activeKey, SetActiveKey] = useState(datapublicitesHomme)
    return (
        <div className='w-full h-max p-2'>
            {isloading && <SpinnerDemarage />}
            <div className='w-full  h-max'>
                <div className={`font-bold  text-[#AF6E04] text-xl mb-4`}>Nos publicités</div>
            </div>
            <div className='sm:mt-5 sm:pl-9'>
                <div className='text-[15px] hidden sm:text-[20px] font-bold text-[#AF6E04]'>Voir aussi</div>
                <div>
                    <div>
                        {publicites.map((data, index) => (
                            <Accordion data-position={index} key={index} activeKey={activeKey} className={` ${index > 0 ? 'border-t-[0.5px]' : ''}  border-gray-400 rounded-[0px]`} defaultExpanded onSelect={() => SetActiveKey(index)}>
                                <Accordion.Panel header={
                                    <div className={`font-bold text-nowrap text-ellipsis overflow-hidden  ${mobile ? mobile2 ? 'text-[11px]' : 'text-[15px]' : 'text-[18px]'}`}>{data && data.titre}</div>

                                } eventKey={index} >
                                    <div className='w-full mt-4'>

                                        <div className='text-[#AF6E04] pl-9 text-[15px]'>Publié, le {data && data.createdAt && new Date(data.createdAt).toLocaleDateString()}</div>

                                        <div className='flex justify-center w-full my-2'>
                                            <div className={`flex justify-center w-[95%] ${mobile3 ? isloading ? 'h-[30vh]' : 'h-max' : 'h-[95vh]'}`}>
                                                <div className='w-full h-full  flex justify-center items-center  relative rounded-md overflow-hidden'>
                                                    {
                                                        youtubeRegex.test(data.link) ? (
                                                            <ReactPlayer
                                                                url={data.link}
                                                                controls
                                                                className="w-full h-full  object-cover"
                                                                onReady={() => {
                                                                    document.querySelector(`[data-position="${index}"] .spinners`).style.display = 'none';
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`https://hathaproject.burundientempsreel.com/uploads/publicites/${data && data.photo}`}

                                                                onLoad={() => {
                                                                    document.querySelector(`[data-position="${index}"] .spinners`).style.display = 'none';
                                                                }}
                                                                className='w-full h-full object-contain object-center ' alt='   ' />
                                                        )}

                                                    <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center  bg-gray-50 spinners">
                                                        <svg className="animate-spin h-8 w-8 text-[#AF6E04]" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#AF6E04" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`pl-2 text-[11px] sm:text-[12px] mt-3 whitespace-break-spaces leading-8`}>
                                            {data && data.description}
                                        </div>
                                    </div>
                                </Accordion.Panel>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
            <Remerciement />
        </div>
    )
}

export default Publicite